import { defineStore } from "pinia";
import { useAccountStore } from "@/stores/account.js";
import axios from "axios";
import { saveAs } from "file-saver";
import { deepCopy, getRandomItem } from "@/util/helpers";
import * as faceapi from "@vladmandic/face-api";
import {
  EXPORTS_WS_API_URL,
  EXPORTS_SECONDARY_WS_API_URL,
  EXPORTS_TERTIARY_WS_API_URL,
  AUTO_CROP_WS_API_URL,
} from "../api.js";

export const useEditStore = defineStore("edit", {
  state: () => ({
    canExport: false,
    initialized: false,
    fetchingShort: false,
    fetchingTranscript: false,
    cropping: false,
    faceDetecting: false,
    exporting: false,
    downloading: false,
    waitingForExport: false,
    estimatedExportingCompletionTimestamp: 0,
    estimatedTimeLeft: 0,
    progress: 0,
    runExport: 0,
    runLayerCrop: 0,
    runRemoveLayer: 0,
    runAddLayer: 0,
    runAutoCrop: 0,
    abortControllerAutoCrop: null,
    aspectRatio: "9:16",
    selectedLayer: null,
    subtitlesConfig: {
      blockVerticalPosition: "bottom", // 'auto' | 'top' | 'middle' | 'bottom'
      maxCharsPerBlock: 20,
      enableUppercase: true,
      transition: "word-bounce", // 'none' | 'subtitle-bounce' | 'subtitle-slideup' | 'subtitle-expand-letter-spacing' | 'word-bounce' | 'word-slideup',
      wordHighlight: "current-only", // wordHighlight: 'current-only' | 'upto-current' | 'background' | 'none';
      enableWordReveal: false,
      enableBackgroundBox: false,
      colors: {
        primaryColor: "#04F827FF",
        secondaryColor: "#FFFFFFFF",
        outlineColor: "#000000FF",
      },
      font: {
        fontName: "Poppins",
        fontWeight: 700,
        fontSize: 75,
        fontStrokeSize: 20,
      },
    },
    brandAssetsMounted: false,
    brandAssetsUrls: [],
    enableSubtitles: true,
    editSubtitles: false,
    subtitlesConfigBottomSheet: false,
  }),
  actions: {
    transcriptToTranscriptWords(transcript, maxCharsPerBlock = 20) {
      const transcriptWords = [];
      for (let i = 0; i < transcript.length; i++) {
        const startSeconds =
          Math.round((parseFloat(transcript[i].start) - parseFloat(transcript[0].start)) * 1000) / 1000;
        const duration =
          i === transcript.length - 1
            ? parseFloat(transcript[i].duration)
            : Math.min(
                parseFloat(transcript[i].duration),
                Math.round((parseFloat(transcript[i + 1].start) - parseFloat(transcript[i].start)) * 1000) / 1000
              );

        let html = transcript[i].html;
        const spanRegex = /<span style="color: #([0-9A-F]{8})">(.*?)<\/span>/gi;
        let coloredWords = {};

        const NEW_LINE = "${NEW_LINE}";
        const baseEmojiRegex =
          /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
        const emojiRegex = new RegExp(baseEmojiRegex.source, "gm");
        const emojiAddSpaceRegex = new RegExp("(\\S)(?=" + baseEmojiRegex.source + ")", "gm");
        const emojiSpaceRegex = new RegExp(baseEmojiRegex.source + "\\s+", "gm");

        // eslint-disable-next-line no-constant-condition
        while (true) {
          let matches = spanRegex.exec(html);
          if (matches === null) break;

          let words = matches[2].split(" ");

          for (let word of words) {
            word = word.replace(emojiRegex, "").trim();
            coloredWords[word.replace(/[.,;:'"!?]/g, "")] = `#${matches[1]}`;
          }
        }

        const text = transcript[i].text
          .replace(/\n/gm, ` ${NEW_LINE} `)
          .replace(emojiAddSpaceRegex, "$1 ")
          .replace(emojiSpaceRegex, "$1")
          .replace(/(\r\n|\n|\r)/gm, " ")
          .trim()
          .replace(/\s+/gm, " ");

        const removeConsecutiveNewLines = (arr) =>
          arr.filter((item, index) => item !== NEW_LINE || (item === NEW_LINE && item !== arr[index + 1]));
        const mergeNewLines = (arr) => {
          const result = [];
          for (let i = 0; i < arr.length; i++) {
            if (arr[i] === NEW_LINE) {
              if (i === arr.length - 1) continue;
              arr[i + 1] = NEW_LINE + arr[i + 1];
            } else result.push(arr[i]);
          }

          return result;
        };

        const textWords = mergeNewLines(removeConsecutiveNewLines(text.split(" "))).filter(
          (e) => e.replace(emojiRegex, "").replaceAll(NEW_LINE, "").length > 0
        );

        if (transcript[i].words && transcript[i].words.length > 0 && textWords.length === transcript[i].words.length) {
          const words = transcript[i].words;
          let currentWordStart = startSeconds;
          let isCurrentWordNewBlockStart = true;
          let blockCharCount = 0;
          let emojis = "";

          words.forEach((e, j) => {
            let currentWord = textWords[j];
            const currentWordEmojisOnly = currentWord.match(emojiRegex)?.join(" ") || "";
            emojis = currentWord.includes(NEW_LINE)
              ? currentWordEmojisOnly
              : `${emojis} ${currentWordEmojisOnly}`.trim();
            currentWord = currentWord.replace(emojiRegex, "").replaceAll(NEW_LINE, "");

            const currentWordOffset = parseFloat(e.offset);

            let currentWordDuration;
            if (j === words.length - 1) currentWordDuration = Math.round((duration - currentWordOffset) * 1000) / 1000;
            else currentWordDuration = Math.round((parseFloat(words[j + 1].offset) - currentWordOffset) * 1000) / 1000;

            const currentWordEnd = Math.round((currentWordStart + currentWordDuration) * 1000) / 1000;

            const currentWordColor = coloredWords[currentWord.replace(/[.,;:'"!?]/g, "")];

            let newBlockCharCount = blockCharCount + (isCurrentWordNewBlockStart ? 0 : 1) + currentWord.length;
            if (newBlockCharCount > maxCharsPerBlock) {
              isCurrentWordNewBlockStart = true;
              blockCharCount = 0;
            }

            transcriptWords.push({
              word: currentWord,
              start: currentWordStart,
              end: currentWordEnd,
              duration: currentWordDuration,
              ...(currentWordColor && { color: currentWordColor }),
              ...(isCurrentWordNewBlockStart && { isNewBlockStart: isCurrentWordNewBlockStart }),
              ...(emojis && { emojis }),
            });

            currentWordStart = currentWordEnd;

            blockCharCount += isCurrentWordNewBlockStart ? currentWord.length : currentWord.length + 1;
            if (isCurrentWordNewBlockStart) isCurrentWordNewBlockStart = false;
          });
        } else {
          const textWithoutEmojisAndNewLines = text
            .replace(emojiRegex, "")
            .replaceAll(NEW_LINE, "")
            .replace(/\s+/gm, " ");
          const textLength = textWithoutEmojisAndNewLines.length;

          const textLengthNoSpaces = textWithoutEmojisAndNewLines.replaceAll(" ", "").length;
          let currentWordStart = startSeconds;
          let isCurrentWordNewBlockStart = true;
          let blockCharCount = 0;
          let emojis = "";

          for (let currentWord of textWords) {
            const currentWordEmojisOnly = currentWord.match(emojiRegex)?.join(" ") || "";
            emojis = currentWord.includes(NEW_LINE)
              ? currentWordEmojisOnly
              : `${emojis} ${currentWordEmojisOnly}`.trim();
            currentWord = currentWord.replace(emojiRegex, "").replaceAll(NEW_LINE, "");
            const currentWordDuration =
              duration * (currentWord.replace(/\{.*?\}/g, "").length / ((textLength + textLengthNoSpaces) / 2));
            const currentWordEnd = currentWordStart + currentWordDuration;

            const currentWordColor = coloredWords[currentWord.replace(/[.,;:'"!?]/g, "")];

            let newBlockCharCount = blockCharCount + (isCurrentWordNewBlockStart ? 0 : 1) + currentWord.length;
            if (newBlockCharCount > maxCharsPerBlock) {
              isCurrentWordNewBlockStart = true;
              blockCharCount = 0;
            }

            if (currentWord)
              transcriptWords.push({
                word: currentWord,
                start: currentWordStart,
                end: currentWordEnd,
                duration: currentWordDuration,
                ...(currentWordColor && { color: currentWordColor }),
                ...(isCurrentWordNewBlockStart && { isNewBlockStart: isCurrentWordNewBlockStart }),
                ...(emojis && { emojis }),
              });

            currentWordStart = currentWordEnd;

            blockCharCount += isCurrentWordNewBlockStart ? currentWord.length : currentWord.length + 1;
            if (isCurrentWordNewBlockStart) isCurrentWordNewBlockStart = false;
          }
        }
      }

      console.log(JSON.stringify(transcriptWords.slice(0, 10), null, 3));
      return transcriptWords;
    },
    async fastExportClip(exportRequestToken, options) {
      return new Promise((resolve, reject) => {
        const accountStore = useAccountStore();
        const wsApiUrl = ["lite", "pro", "premium"].includes(accountStore.accountTier)
          ? EXPORTS_WS_API_URL
          : getRandomItem([EXPORTS_SECONDARY_WS_API_URL, EXPORTS_TERTIARY_WS_API_URL]);
        const ws = new WebSocket(`${wsApiUrl}?token=${exportRequestToken}`);

        let pingTimeout = null;

        const heartbeat = () => {
          clearTimeout(pingTimeout);

          pingTimeout = setTimeout(() => {
            ws.close();
            reject(new Error("FAST_EXPORTING_FAILED"));
          }, 45000);

          this.estimatedTimeLeft = Math.max(1, Math.floor((this.estimatedExportingCompletionTimestamp - Date.now()) / 60000));
        };

        ws.onopen = () => {
          heartbeat();
          ws.send(JSON.stringify({ event: "export-clip", options }));
        };

        ws.onmessage = (e) => {
          heartbeat();
          const data = JSON.parse(e.data);
          const eventName = data.event;

          switch (eventName) {
            case "average-exporting-time":
              this.estimatedExportingCompletionTimestamp = Date.now() + data.seconds * 1000;
              this.estimatedTimeLeft = Math.max(1, Math.floor((this.estimatedExportingCompletionTimestamp - Date.now()) / 60000));
              break;
            case "clip-exporting-started":
              this.waitingForExport = false;
              this.progress = 0;
              break;
            case "clip-exporting-progress":
              this.waitingForExport = false;
              this.progress = data.progress >= 0 && data.progress <= 100 ? data.progress : 0;
              break;
            case "clip-url":
              resolve();
              fetch(data.clipUrl)
                .then((response) => {
                  this.downloading = true;
                  this.progress = 0;
                  const contentLength = response.headers.get("content-length");
                  const total = contentLength ? parseInt(contentLength, 10) : 0;
                  let loaded = 0;

                  if (!response.body || !response.ok) {
                    throw new Error("DOWNLOADING_FAILED");
                  }

                  const reader = response.body.getReader();
                  const stream = new ReadableStream({
                    start: (controller) => {
                      const read = () => {
                        reader
                          .read()
                          .then(({ done, value }) => {
                            if (done) {
                              controller.close();
                              return;
                            }
                            loaded += value.byteLength;
                            this.progress = total ? Math.round((loaded / total) * 100) : 0;
                            controller.enqueue(value);
                            read();
                          })
                          .catch((error) => {
                            this.exporting = false;
                            this.downloading = false;
                            controller.error(error);
                          });
                      };
                      read();
                    },
                  });

                  return new Response(stream);
                })
                .then((response) => response.blob())
                .then((blob) => {
                  this.progress = 0;
                  this.exporting = false;
                  this.downloading = false;
                  this.estimatedExportingCompletionTimestamp = 0;
                  this.estimatedTimeLeft = 0;
                  saveAs(blob, options.outputName);
                })
                .catch(() => {
                  this.progress = 0;
                  this.exporting = false;
                  this.downloading = false;
                  this.estimatedExportingCompletionTimestamp = 0;
                  this.estimatedTimeLeft = 0;
                  window.open(data.clipUrl, "_blank");
                });
              break;
          }
        };

        ws.onclose = () => {
          clearTimeout(pingTimeout);
          reject(new Error("FAST_EXPORTING_FAILED"));
        };

        ws.onerror = () => {
          clearTimeout(pingTimeout);
          this.progress = 0;
          this.exporting = false;
          this.estimatedExportingCompletionTimestamp = 0;
          this.estimatedTimeLeft = 0;
          reject(new Error("FAST_EXPORTING_FAILED"));
        };
      });
    },
    async faceDetectionsToConfig(options, minFaceDetectionConfidence = 0.3, padding = 75, detections = [], url = null) {
      try {
        if (url) {
          const resp = await axios.get(url, { withCredentials: false });
          detections = resp.data;
        }

        let start = parseFloat(options.start);
        let end = parseFloat(options.end);

        if (start === Infinity || start === end) {
          start = 0;
          end = options.inputDuration;
        }

        const aspectRatio = options.outputWidth / options.outputHeight;
        const fps = 1;

        const layers = [];
        const defaultLayerOutputLayerHeight = aspectRatio < 1 ? options.outputHeight / 2 : options.outputHeight;
        const defaultLayer = {
          type: "default",
          inputLayerTop: 0,
          inputLayerLeft: 0,
          inputLayerWidth: options.inputWidth,
          inputLayerHeight: options.inputHeight,
          outputLayerTop: (options.outputHeight - defaultLayerOutputLayerHeight) / 2,
          outputLayerLeft: 0,
          outputLayerWidth: options.outputWidth,
          outputLayerHeight: defaultLayerOutputLayerHeight,
          enableBetween: [{ start: 0, end: 0 }],
        };

        let noFacesInRow = 0;

        if (options.outputWidth / options.outputHeight > 1) {
          const l = deepCopy(defaultLayer);
          l.enableBetween[0] = { start, end };

          return {
            ...options,
            layers: [l],
          };
        }

        const computeFaceLandmarkScore = (face, currentLayers) => {
          // Define upper and lower lip indices
          const upperLipIndices = [0, 1, 2, 3, 4, 5, 6, 16, 17, 18, 19];
          const lowerLipIndices = [6, 7, 8, 9, 10, 11, 0, 12, 13, 14, 15, 16];
          const bottomUpperLipIndices = [16, 17, 18, 19];
          const topLowerLipIndices = [12, 13, 14, 15];

          // Compute average position for upper and lower lip
          let upperLipAverage = computeAveragePosition(face.mouth, upperLipIndices);
          let lowerLipAverage = computeAveragePosition(face.mouth, lowerLipIndices);

          // Compute average position for bottom of the upper lip and top of the lower lip
          let bottomUpperLipAverage = computeAveragePosition(face.mouth, bottomUpperLipIndices);
          let topLowerLipAverage = computeAveragePosition(face.mouth, topLowerLipIndices);

          // Compute Euclidean distances
          const verticalLipDistance = computeDistance(upperLipAverage, lowerLipAverage);
          const speakingDistance = computeDistance(bottomUpperLipAverage, topLowerLipAverage);

          const faceCenter = {
            x: face.detection._box._x + face.detection._box._width / 2,
            y: face.detection._box._y + face.detection._box._height / 2,
          };

          let multiplier = 1;
          for (let layer of currentLayers) {
            if (
              layer.type !== "default" &&
              faceCenter.x >= layer.inputLayerLeft + padding &&
              faceCenter.x <= layer.inputLayerLeft + layer.inputLayerWidth - padding &&
              faceCenter.y >= layer.inputLayerTop + padding &&
              faceCenter.y <= layer.inputLayerTop + layer.inputLayerHeight - padding
            ) {
              // If the face is already within a layer, increase its score slightly
              multiplier += 0.1;
              break;
            }
          }

          // Return the sum of the distances as the score
          return verticalLipDistance * speakingDistance * multiplier;
        };

        const computeAveragePosition = (landmarks, indices) => {
          let average = { x: 0, y: 0 };
          indices.forEach((i) => {
            average.x += landmarks[i]._x;
            average.y += landmarks[i]._y;
          });
          average.x /= indices.length;
          average.y /= indices.length;
          return average;
        };

        const computeDistance = (point1, point2) => {
          return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
        };

        let currentLayers = [];

        detections.forEach((detection, i) => {
          const currentSeconds = (1 / fps) * i;
          const layerWidth = aspectRatio * options.inputHeight;
          const layerHeight = options.inputHeight;
          const maxNumFaces = aspectRatio === 1 ? 1 : 2;

          detection.detections = detection.detections.filter((face) => {
            const minFaceWidthPercentage = 5;
            const faceWidthPercentage = (face.detection._box._width / face.detection._imageDims._width) * 100;
            return face.detection._score > minFaceDetectionConfidence && faceWidthPercentage >= minFaceWidthPercentage;
          });

          // If more than two faces are detected, filter them based on detection score and mouth landmarks.
          if (detection.detections.length > maxNumFaces) {
            // First, filter detections based on score
            let filteredFaces = detection.detections.filter(
              (face) => face.detection._score > Math.min(0.9, minFaceDetectionConfidence * 1.5)
            );

            // Then, sort the faces by the mouth landmark (assuming you have a function to compute mouth landmark score)
            // The faces array is sorted in descending order of landmark score.
            filteredFaces.sort(
              (faceA, faceB) =>
                computeFaceLandmarkScore(faceB, currentLayers) - computeFaceLandmarkScore(faceA, currentLayers)
            );

            // Take the top two faces
            filteredFaces = filteredFaces.slice(0, maxNumFaces);

            // Replace detection.detections with the filtered faces
            detection.detections = filteredFaces;
          }

          const minFaceCenterDistancePercentage = 10;
          const minFaceCenterXDistancePercentage = 5;
          let numFaces = detection.detections.length;
          if (numFaces === 2) {
            const center1 = {
              x: detection.detections[0].detection._box._x + detection.detections[0].detection._box._width / 2,
              y: detection.detections[0].detection._box._y + detection.detections[0].detection._box._height / 2,
            };

            const center2 = {
              x: detection.detections[1].detection._box._x + detection.detections[1].detection._box._width / 2,
              y: detection.detections[1].detection._box._y + detection.detections[1].detection._box._height / 2,
            };

            const distance = Math.sqrt(Math.pow(center2.x - center1.x, 2) + Math.pow(center2.y - center1.y, 2));
            const xDistance = Math.abs(center1.x - center2.x);
            const distancePercentage = (distance / detection.detections[0].detection._imageDims._width) * 100;
            const xDistancePercentage = (xDistance / detection.detections[0].detection._imageDims._width) * 100;

            if (
              distancePercentage < minFaceCenterDistancePercentage ||
              (distancePercentage < minFaceCenterDistancePercentage * 1.75 &&
                xDistancePercentage < minFaceCenterXDistancePercentage)
            )
              numFaces = 1;
          }

          if (numFaces === 0) {
            if (noFacesInRow >= fps) {
              // If there's only one current layer and it's the default layer, extend it
              if (currentLayers.length === 1 && currentLayers[0].type === "default") {
                currentLayers[0].enableBetween[0].end = currentSeconds;
              } else {
                // Otherwise, close all current layers
                currentLayers.forEach((layer) => {
                  layer.enableBetween[0].end = currentSeconds;
                  layers.push(layer);
                });

                // Clear the currentLayers array
                currentLayers = [];

                // Create and add a new default layer
                const defaultLayerCopy = deepCopy(defaultLayer);
                defaultLayerCopy.enableBetween[0].start = currentSeconds;
                defaultLayerCopy.enableBetween[0].end = currentSeconds;
                currentLayers.push(defaultLayerCopy);
              }
            }

            noFacesInRow++;
            return;
          }
          if (numFaces === 1) {
            const face = detection.detections[0];
            const center = {
              x: face.detection._box._x + face.detection._box._width / 2,
              y: face.detection._box._y + face.detection._box._height / 2,
            };

            let faceInExistingLayer = false;

            // Check if the face is inside any of the current layers.
            for (let layer of currentLayers) {
              if (
                layer.type !== "default" &&
                layer.type === "fill" &&
                center.x >= layer.inputLayerLeft + padding &&
                center.x <= layer.inputLayerLeft + layer.inputLayerWidth - padding &&
                center.y >= layer.inputLayerTop + padding &&
                center.y <= layer.inputLayerTop + layer.inputLayerHeight - padding
              ) {
                // The face is within the current layer, so extend the end of the layer
                layer.enableBetween[0].end = currentSeconds;
                faceInExistingLayer = true;
                break;
              }
            }

            if (!faceInExistingLayer) {
              // The face is outside all current layers, so finalize them
              currentLayers.forEach((layer) => {
                layer.enableBetween[0].end = currentSeconds;
                layers.push(layer);
              });

              // Then empty the currentLayers array
              currentLayers = [];

              // And create a new layer
              const layerTop = 0;
              let layerLeft = center.x - layerWidth / 2;
              if (layerLeft + layerWidth > options.inputWidth) {
                const diff = layerLeft + layerWidth - options.inputWidth;
                layerLeft = layerLeft - diff;
              }

              const newLayer = {
                type: "fill",
                inputLayerTop: layerTop,
                inputLayerLeft: layerLeft,
                inputLayerWidth: layerWidth,
                inputLayerHeight: layerHeight,
                outputLayerTop: 0,
                outputLayerLeft: 0,
                outputLayerWidth: options.outputWidth,
                outputLayerHeight: options.outputHeight,
                enableBetween: [{ start: currentSeconds, end: currentSeconds }],
              };

              currentLayers.push(newLayer);
            }
          } else if (numFaces === 2) {
            // For two faces, compute individual center points
            const center1 = {
              x: detection.detections[0].detection._box._x + detection.detections[0].detection._box._width / 2,
              y: detection.detections[0].detection._box._y + detection.detections[0].detection._box._height / 2,
            };

            const center2 = {
              x: detection.detections[1].detection._box._x + detection.detections[1].detection._box._width / 2,
              y: detection.detections[1].detection._box._y + detection.detections[1].detection._box._height / 2,
            };

            let centers = [center1, center2];

            // List of layers to keep in currentLayers
            let keepLayers = [];

            currentLayers.forEach((layer) => {
              let faceInLayer = false;

              for (let center of centers) {
                if (
                  layer.type !== "default" &&
                  layer.type === "split" &&
                  center.x >= layer.inputLayerLeft + padding &&
                  center.x <= layer.inputLayerLeft + layer.inputLayerWidth - padding &&
                  center.y >= layer.inputLayerTop + padding &&
                  center.y <= layer.inputLayerTop + layer.inputLayerHeight - padding
                ) {
                  // The face is within this layer, so extend the end of the layer and keep it
                  layer.enableBetween[0].end = currentSeconds;
                  keepLayers.push(layer);
                  faceInLayer = true;
                  // Remove the center from the list, it has already been processed
                  centers = centers.filter((c) => c !== center);
                  break;
                }
              }

              if (!faceInLayer) {
                // The face is outside the current layer, so finalize this layer
                layer.enableBetween[0].end = currentSeconds;
                layers.push(layer);
              }
            });

            // Now, process remaining centers and create new layers for them
            centers.forEach((center) => {
              let layerLeft = center.x - layerWidth / 2;

              // Adjust the top so the center of face is approximately in the center of the input layer
              let layerTop = center.y - layerHeight / 4;

              // Make sure the inputLayer doesn't go outside of the video dimensions
              if (layerTop < 0) {
                layerTop = 0;
              } else if (layerTop + layerHeight / 2 > options.inputHeight) {
                layerTop = options.inputHeight - layerHeight / 2;
              }

              if (layerLeft + layerWidth > options.inputWidth) {
                const diff = layerLeft + layerWidth - options.inputWidth;
                layerLeft = layerLeft - diff;
              }

              // Check whether there is already a layer in the bottom half
              const isBottomHalfOccupied = keepLayers.some(
                (layer) => layer.outputLayerTop === options.outputHeight / 2
              );

              const newLayer = {
                type: "split",
                inputLayerTop: layerTop,
                inputLayerLeft: layerLeft,
                inputLayerWidth: layerWidth,
                // Half the layer height as per requirement
                inputLayerHeight: layerHeight / 2,
                // Set top according to whether the bottom half is already occupied
                outputLayerTop: isBottomHalfOccupied ? 0 : options.outputHeight / 2,
                outputLayerLeft: 0,
                outputLayerWidth: options.outputWidth,
                outputLayerHeight: options.outputHeight / 2,
                enableBetween: [{ start: currentSeconds, end: currentSeconds }],
              };

              keepLayers.push(newLayer);
            });

            // Replace currentLayers with the layers we want to keep
            currentLayers = keepLayers;
          }
        });

        currentLayers.forEach((layer) => {
          layer.enableBetween[0].end = end;
          layers.push(layer);
        });

        const filteredLayers = layers.filter((l) => l.enableBetween[0].end > start && l.enableBetween[0].start < end);

        filteredLayers.forEach((layer) => {
          if (layer.enableBetween[0].start < start) layer.enableBetween[0].start = start;
          if (layer.enableBetween[0].end > end) layer.enableBetween[0].end = end;
        });

        const calculateMaxOverlapPercentage = (layer1, layer2) => {
          const getOverlap = (start1, end1, start2, end2) => {
            // Calculate the max of the two starts and the min of the two ends
            const maxStart = Math.max(start1, start2);
            const minEnd = Math.min(end1, end2);

            // If there is an overlap, return it, otherwise return 0
            return minEnd > maxStart ? minEnd - maxStart : 0;
          };

          // Calculate the horizontal and vertical overlaps
          const overlapWidth = getOverlap(
            layer1.inputLayerLeft,
            layer1.inputLayerLeft + layer1.inputLayerWidth,
            layer2.inputLayerLeft,
            layer2.inputLayerLeft + layer2.inputLayerWidth
          );
          const overlapHeight = getOverlap(
            layer1.inputLayerTop,
            layer1.inputLayerTop + layer1.inputLayerHeight,
            layer2.inputLayerTop,
            layer2.inputLayerTop + layer2.inputLayerHeight
          );

          // Calculate the area of the overlap
          const overlapArea = overlapWidth * overlapHeight;

          // Calculate the area of each layer's inputLayer
          const area1 = layer1.inputLayerWidth * layer1.inputLayerHeight;
          const area2 = layer2.inputLayerWidth * layer2.inputLayerHeight;

          // Calculate overlap percentage for each layer
          const overlapPercentage1 = (overlapArea / area1) * 100;
          const overlapPercentage2 = (overlapArea / area2) * 100;

          // Return the maximum of the two percentages
          return Math.max(overlapPercentage1, overlapPercentage2);
        };

        const getLayersStartingAt = (layers, start) => {
          const matchingLayers = [];

          for (let layer of layers) {
            let foundMatch = false;

            for (let i = 0; i < layer.enableBetween.length && !foundMatch; i++) {
              const timeFrame = layer.enableBetween[i];

              if (timeFrame.start === start) {
                const duration = timeFrame.end - timeFrame.start;
                matchingLayers.push({ layer, duration });
                foundMatch = true;
              }
            }
          }

          return matchingLayers;
        };

        const getLayersEndingAt = (layers, end) => {
          const matchingLayers = [];

          for (let layer of layers) {
            let foundMatch = false;

            for (let i = 0; i < layer.enableBetween.length && !foundMatch; i++) {
              const timeFrame = layer.enableBetween[i];

              if (timeFrame.end === end) {
                const duration = timeFrame.end - timeFrame.start;
                matchingLayers.push({ layer, duration });
                foundMatch = true;
              }
            }
          }

          return matchingLayers;
        };

        let timestamps0 = [start, end];
        filteredLayers.forEach((layer) => {
          layer.enableBetween.forEach((range) => {
            timestamps0.push(range.start, range.end);
          });
        });
        timestamps0 = [...new Set(timestamps0)].sort((a, b) => a - b);

        const minLayerDuration = 3;
        const minOverlapPercentage = 50;
        const filteredLayers2 = filteredLayers.filter((l) => {
          if (l.enableBetween[0].end - l.enableBetween[0].start >= minLayerDuration) return true;
          else if (l.type !== "default") {
            const start = l.enableBetween[0].start;
            const end = l.enableBetween[0].end;
            const timestampStartIndex = timestamps0.indexOf(start);
            const timestampEndIndex = timestamps0.indexOf(end);

            const duration = end - start;
            let startOverlapDuration = 0;
            let endOverlapDuration = 0;

            // Loop from index to the start of the array
            for (let i = timestampStartIndex; i >= 0; i--) {
              const layers = getLayersEndingAt(
                filteredLayers.filter((e) => e.type !== "default"),
                start - startOverlapDuration
              );

              const layerOverlaps = layers.map((e) => ({
                duration: e.duration,
                overlap: calculateMaxOverlapPercentage(l, e.layer),
              }));

              const increment =
                layerOverlaps.sort((a, b) => b.overlap - a.overlap).find((o) => o.overlap > minOverlapPercentage)
                  ?.duration || null;

              if (increment === null) break;
              else {
                startOverlapDuration += increment;
                i = timestamps0.indexOf(start - startOverlapDuration);
              }
            }

            // Loop from index to the end of the array
            for (let i = timestampEndIndex; i < timestamps0.length; i++) {
              const layers = getLayersStartingAt(
                filteredLayers.filter((e) => e.type !== "default"),
                end + endOverlapDuration
              );

              const layerOverlaps = layers.map((e) => ({
                duration: e.duration,
                overlap: calculateMaxOverlapPercentage(l, e.layer),
              }));

              const increment =
                layerOverlaps.sort((a, b) => b.overlap - a.overlap).find((o) => o.overlap > minOverlapPercentage)
                  ?.duration || null;

              if (increment === null) break;
              else {
                endOverlapDuration += increment;
                i = timestamps0.indexOf(end + endOverlapDuration);
              }
            }

            return duration + startOverlapDuration + endOverlapDuration >= minLayerDuration;
          } else return false;
        });

        let timestamps = [start, end];
        filteredLayers2.forEach((layer) => {
          layer.enableBetween.forEach((range) => {
            timestamps.push(range.start, range.end);
          });
          layer.inputLayerTop = Math.max(0, layer.inputLayerTop);
          layer.inputLayerLeft = Math.max(0, layer.inputLayerLeft);
        });
        timestamps = [...new Set(timestamps)].sort((a, b) => a - b);

        const countLayersInRange = (layers, start, end) => {
          let count = 0;
          layers.forEach((l) => {
            if (l.enableBetween.some((range) => range.start < end && range.end > start)) count++;
          });

          return count;
        };

        for (let i = 0; i < filteredLayers2.length; i++) {
          const layer = filteredLayers2[i];
          if (layer.type === "split") {
            const start = layer.enableBetween[0].start;
            const end = layer.enableBetween[0].end;
            const timestampStartIndex = timestamps.indexOf(start);
            const timestampEndIndex = timestamps.indexOf(end);

            if (
              timestampStartIndex + 1 === timestampEndIndex &&
              countLayersInRange(filteredLayers2, start, end) === 1
            ) {
              layer.type = "fill";
              layer.inputLayerTop = 0;
              layer.inputLayerHeight = options.inputHeight;
              layer.outputLayerTop = 0;
              layer.outputLayerLeft = 0;
              layer.outputLayerWidth = options.outputWidth;
              layer.outputLayerHeight = options.outputHeight;
            } else {
              const ts = timestamps.slice(timestampStartIndex + 1, timestampEndIndex);
              const newLs = [];
              let newLayerEnd = null;

              for (let index = 0; index < ts.length; index++) {
                const timestamp = ts[index];
                const nextTimestamp = index === ts.length - 1 ? end : ts[index + 1];
                const middle = (timestamp + nextTimestamp) / 2;

                if (countLayersInRange(filteredLayers2, middle, middle) === 1) {
                  if (!newLayerEnd) newLayerEnd = timestamp;
                  const l = deepCopy(layer);
                  l.enableBetween[0] = { start: timestamp, end: nextTimestamp };
                  l.type = "fill";
                  l.inputLayerTop = 0;
                  l.inputLayerHeight = options.inputHeight;
                  l.outputLayerTop = 0;
                  l.outputLayerLeft = 0;
                  l.outputLayerWidth = options.outputWidth;
                  l.outputLayerHeight = options.outputHeight;
                  newLs.push(l);

                  if (nextTimestamp !== end) {
                    const l2 = deepCopy(layer);
                    l2.enableBetween[0].start = nextTimestamp;
                    newLs.push(l2);
                  }
                }
              }

              if (newLayerEnd !== null) layer.enableBetween[0].end = newLayerEnd;
              filteredLayers2.push(...newLs);
            }
          }
        }

        // Fill empty gaps with default layer
        for (let index = 0; index < timestamps.length - 1; index++) {
          const timestamp = timestamps[index];
          const nextTimestamp = timestamps[index + 1];
          const middle = (timestamp + nextTimestamp) / 2;

          const isTimeInRange = filteredLayers2.some((layer) =>
            layer.enableBetween.some((range) => middle >= range.start && middle <= range.end)
          );
          if (!isTimeInRange) {
            const l = deepCopy(defaultLayer);
            l.enableBetween[0] = { start: timestamp, end: nextTimestamp };
            filteredLayers2.push(l);
          }
        }

        // Consolidate/merge nearly identical layers
        const minConsolidationOverlapPercentage = 75;
        const consolidateLayers = (layers) => {
          for (let index = 0; index < layers.length; index++) {
            const layer = layers[index];
            const end = layer.enableBetween[0].end;

            const ls = getLayersStartingAt(
              layers.filter((e) => e.type === layer.type),
              end
            );

            const lsOverlaps = ls.map((e) => ({
              layer: e.layer,
              overlap: calculateMaxOverlapPercentage(layer, e.layer),
            }));

            const lForConsolidation =
              lsOverlaps
                .sort((a, b) => b.overlap - a.overlap)
                .find((o) => o.overlap > minConsolidationOverlapPercentage)?.layer || null;
            if (lForConsolidation) {
              const lForConsolidationIndex = layers.findIndex(
                (l) => JSON.stringify(l) === JSON.stringify(lForConsolidation)
              );
              const newEnd = lForConsolidation.enableBetween[0].end;
              layers[index].enableBetween[0].end = newEnd;
              layers.splice(lForConsolidationIndex, 1);
              consolidateLayers(layers);
              break;
            }
          }
          return layers;
        };

        const sortedFilteredLayers2 = filteredLayers2.sort(
          (a, b) => a.enableBetween[0].start - b.enableBetween[0].start
        );
        const consolidatedSortedFilteredLayers = consolidateLayers(sortedFilteredLayers2);

        return {
          ...options,
          layers: consolidatedSortedFilteredLayers,
        };
      } catch (error) {
        console.log(error);
        throw new Error("FACE_DETECTIONS_TO_CONFIG_FAILED");
      }
    },
    async detectFaces(url, clipDuration, signal) {
      try {
        await faceapi.loadTinyFaceDetectorModel("/weights");
        await faceapi.loadSsdMobilenetv1Model("/weights");
        await faceapi.loadFaceLandmarkModel("/weights");

        const video = document.createElement("video");
        video.src = url;
        video.muted = true;
        video.crossOrigin = "anonymous";
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d", { willReadFrequently: true });

        let aborted = false;

        return new Promise((resolve, reject) => {
          const onAbort = () => {
            aborted = true;
            reject(new Error("DETECTING_FACES_ABORTED"));
          };

          const detectionPromises = [];
          const startMs = Date.now();

          let frameCount = 1;

          video.addEventListener("loadedmetadata", () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            video.currentTime = 0.5;
          });

          video.addEventListener("seeked", () => {
            if (video.currentTime > clipDuration || video.currentTime >= video.duration) {
              console.log("Done capturing frames.");
              Promise.all(detectionPromises)
                .then((rawDetections) => {
                  const sortedRawDetections = rawDetections.sort((a, b) => Number(a.frameId) - Number(b.frameId));
                  console.log(
                    `(canvas) Face detection finished in ${Math.round((Date.now() - startMs) / 1000)}s - ${
                      rawDetections.length
                    } detections`
                  );
                  resolve(
                    sortedRawDetections.map((e) => ({
                      frameId: e.frameId,
                      detections: e.detections.map((e) => ({
                        detection: e.detection.detection || e.detection,
                        mouth: e.detection.landmarks ? e.detection.landmarks.getMouth() : e.landmarks.getMouth(),
                      })),
                    }))
                  );
                })
                .catch(reject);
            } else if (!aborted) {
              context.drawImage(video, 0, 0, canvas.width, canvas.height);

              // For testing only (downloads captured image)
              //   const frameData = canvas.toDataURL("image/jpeg");
              //   faceapi.fetchImage(frameData).then(async (img) => {
              //     const downloadImageUrl = (url, filename = "downloaded_image") => {
              //         const a = document.createElement("a");
              //         a.href = url;
              //         a.download = filename;
              //         document.body.appendChild(a);
              //         a.click();
              //         document.body.removeChild(a);
              //       };
              //     const frameId = frameCount.toString().padStart(3, "0");
              //     if (frameId === "001") downloadImageUrl(img.src, `${frameId}.png`);
              //   });

              (async () => {
                const frameId = frameCount.toString().padStart(3, "0");

                const options = new faceapi.SsdMobilenetv1Options({ minConfidence: 0.3 });
                const tinyOptions = new faceapi.TinyFaceDetectorOptions({ inputSize: 512, scoreThreshold: 0.6 });

                let detections = await faceapi.detectAllFaces(canvas, options).withFaceLandmarks();

                if (detections.length === 0)
                  detections = await faceapi.detectAllFaces(canvas, tinyOptions).withFaceLandmarks();

                detectionPromises.push({ frameId, detections });

                const progress = Math.round((detectionPromises.length * 100) / clipDuration);
                this.progress = progress >= 0 && progress <= 100 ? progress : 0;

                frameCount += 1;
                video.currentTime += 1;
              })().catch(reject);
            }
          });

          signal.addEventListener("abort", onAbort);

          video.load();
        });
      } catch (error) {
        console.log(error);
        throw new Error("DETECTING_FACES_FAILED");
      }
    },
    async fastDetectFaces(autoCropRequestToken) {
      return new Promise((resolve, reject) => {
        const ws = new WebSocket(`${AUTO_CROP_WS_API_URL}?token=${autoCropRequestToken}`);

        let pingTimeout = null;

        const heartbeat = () => {
          clearTimeout(pingTimeout);

          pingTimeout = setTimeout(() => {
            ws.close();
            reject(new Error("FAST_DETECTING_FACES_FAILED"));
          }, 30000 + 1000);
        };

        ws.onopen = () => {
          heartbeat();
          ws.send(JSON.stringify({ event: "detect-faces" }));
        };

        ws.onmessage = (e) => {
          heartbeat();
          const data = JSON.parse(e.data);
          const eventName = data.event;

          switch (eventName) {
            case "frames-extracting-progress":
              this.progress = data.progress >= 0 && data.progress <= 100 ? Math.round(data.progress / 2) : 0;
              break;
            case "face-detecting-progress":
              this.progress = data.progress >= 0 && data.progress <= 100 ? Math.round(data.progress / 2 + 50) : 0;
              break;
            case "face-detection-url":
              resolve(data.faceDetectionUrl);
              this.progress = 0;
              break;
          }
        };

        ws.onclose = () => {
          clearTimeout(pingTimeout);
          reject(new Error("FAST_DETECTING_FACES_FAILED"));
        };

        ws.onerror = () => {
          clearTimeout(pingTimeout);
          this.progress = 0;
          this.exporting = false;
          reject(new Error("FAST_DETECTING_FACES_FAILED"));
        };
      });
    },
    async uploadFaceDetections(detections, presignedPost) {
      try {
        const formData = new FormData();
        Object.keys(presignedPost.fields).forEach((key) => formData.append(key, presignedPost.fields[key]));

        const file = new Blob([JSON.stringify(detections)], { type: "application/json" });
        formData.append("file", file);

        try {
          await axios.request({
            method: "POST",
            url: presignedPost.url,
            data: formData,
            withCredentials: false,
          });
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
