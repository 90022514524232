<template>
  <v-dialog v-model="editStore.editSubtitles" max-width="600px" scrollable>
    <SubtitlesEditCard />
  </v-dialog>
</template>

<script>
import { useEditStore } from "@/stores/edit";
import SubtitlesEditCard from "@/components/edit/SubtitlesEditCard.vue";

export default {
  name: "SubtitlesEditDialog",
  components: {
    SubtitlesEditCard,
  },
  setup() {
    const editStore = useEditStore();
    return { editStore };
  },
};
</script>

<style scoped>
:deep(.v-dialog),
:deep(.v-dialog .v-card) {
  border-radius: 16px;
}
</style>