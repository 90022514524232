<template>
  <v-dialog
    v-model="subtitlesPresetStore.managePresets"
    max-width="600px"
    scrollable
  >
    <SubtitlesPresetsCard />
  </v-dialog>
</template>

<script>
import { useSubtitlesPresetStore } from "@/stores/subtitles-preset";
import SubtitlesPresetsCard from "@/components/edit/SubtitlesPresetsCard.vue";

export default {
  name: "SubtitlesPresetsDialog",
  components: {
    SubtitlesPresetsCard,
  },
  setup() {
    const subtitlesPresetStore = useSubtitlesPresetStore();
    return { subtitlesPresetStore };
  },
};
</script>

<style scoped>
:deep(.v-dialog),
:deep(.v-dialog .v-card) {
  border-radius: 16px;
}
</style>