<template>
  <v-card>
    <v-card-title class="font-weight-bold d-flex align-center">
      <v-btn v-if="screen !== 'presets'" icon @click="screen = 'presets'" class="mr-2">
        <v-icon>mdi-arrow-left-circle</v-icon>
      </v-btn>
      <div class="flex-grow-1 mr-2 text-truncate" style="width: 0">
        {{
          screen === "presets"
            ? $t("subtitleStylePresets")
            : screen === "newPreset"
            ? $t("newSubtitleStylePreset")
            : preset.name
        }}
      </div>
      <v-btn icon @click="subtitlesPresetStore.managePresets = false">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader :loading="loading" type="list-item-two-line, list-item-two-line" class="pa-0">
        <template v-slot:default>
          <div v-show="screen === 'presets'">
            <v-responsive
              v-if="subtitlesPresetStore.subtitlesPresets.length === 0"
              height="150"
              class="d-flex justify-center align-center"
            >
              <div class="text-center">
                <div :class="$vuetify.theme.dark ? 'text-h5 mb-1 white--text' : 'text-h5 mb-1 initial-color'">
                  {{ $t("noPresets") }}
                </div>
                <div class="body-2 text--secondary mb-4">
                  {{ $t("youHaveNoPresetsInYourAccount") }}
                </div>
                <v-btn small rounded depressed outlined color="primary" @click="initNewPreset()">
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t("newPreset") }}</v-btn
                >
              </div>
            </v-responsive>
            <v-list v-else rounded :disabled="!!loadingPresetId" class="pa-0">
              <v-list-item
                v-for="preset in subtitlesPresetStore.subtitlesPresets"
                :key="preset._id"
                class="ma-0"
                @click="viewPreset(preset._id)"
              >
                <v-list-item-avatar>
                  <v-progress-circular
                    v-if="loadingPresetId === preset._id"
                    indeterminate
                    size="20"
                    color="primary"
                  ></v-progress-circular>
                  <v-icon v-else color="primary">mdi-subtitles-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>{{ preset.name }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click.prevent="initNewPreset()">
                <v-list-item-avatar>
                  <v-icon color="primary">mdi-plus</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>{{ $t("newPreset") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
          <div v-if="screen !== 'presets'">
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="preset.name"
                :label="$t('presetName')"
                :rules="[rules.required]"
                dense
                rounded
                filled
                hide-details
                class="mb-4"
              ></v-text-field>

              <h4 class="text--primary">
                {{ $t("subtitleTransition") }}
              </h4>
              <v-chip-group mandatory v-model="preset.transition" active-class="primary--text" column class="mb-4">
                <v-chip v-for="transition in transitions" :key="transition.value" :value="transition.value" filter>
                  {{ transition.text }}
                </v-chip>
              </v-chip-group>

              <h4 class="text--primary">
                {{ $t("wordHighlight") }}
              </h4>
              <div class="mb-4">
                <v-chip-group
                  mandatory
                  v-model="preset.wordHighlight"
                  active-class="primary--text"
                  column
                  @change="preset.wordHighlight === 'background' ? (preset.enableBackgroundBox = false) : null"
                >
                  <v-chip
                    v-for="wordHighlight in wordHighlights"
                    :key="wordHighlight.value"
                    :value="wordHighlight.value"
                    filter
                  >
                    {{ wordHighlight.text }}
                  </v-chip>
                </v-chip-group>

                <v-menu
                  v-if="preset.wordHighlight !== 'none'"
                  offset-y
                  transition="slide-y-transition"
                  rounded="lg"
                  :close-on-content-click="false"
                  :max-width="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="preset.colors.primaryColor"
                      readonly
                      :label="$t('primaryColor')"
                      rounded
                      filled
                      dense
                      single-line
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      class="mb-2"
                    >
                      <template v-slot:prepend-inner>
                        <v-icon :color="preset.colors.primaryColor" class="mr-2">mdi-circle</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-color-picker v-model="preset.colors.primaryColor" mode="hexa"></v-color-picker>
                </v-menu>
              </div>

              <h4 class="text--primary">
                {{ $t("subtitleSplitting") }}
              </h4>
              <span class="subtitle-2 text--secondary">{{ $t("subtitleSplittingCaption") }}</span>
              <v-chip-group
                mandatory
                v-model="preset.maxCharsPerBlock"
                active-class="primary--text"
                column
                class="mb-4"
              >
                <v-chip v-for="splitting in splittings" :key="splitting.value" :value="splitting.value" filter>
                  {{ splitting.text }}
                </v-chip>
              </v-chip-group>

              <h4 class="text--primary">
                {{ $t("fontSettings") }}
              </h4>
              <div class="d-flex my-2">
                <v-select
                  class="flex-grow-1"
                  style="border-radius: 28px 0 0 28px; width: 0"
                  v-model="preset.font.fontName"
                  :items="['Roboto', 'Montserrat', 'Poppins', 'Playpen Sans']"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    'offset-y': true,
                    rounded: 'lg',
                  }"
                  :label="$t('fontName')"
                  rounded
                  dense
                  filled
                  hide-details
                  append-icon
                >
                </v-select>
                <v-select
                  class="flex-grow-1"
                  style="border-radius: 0; width: 0"
                  v-model="preset.font.fontWeight"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    'offset-y': true,
                    rounded: 'lg',
                  }"
                  :label="$t('fontWeight')"
                  :items="[
                    { value: 400, text: $t('regular') },
                    { value: 700, text: $t('bold') },
                  ]"
                  rounded
                  dense
                  filled
                  hide-details
                  append-icon
                >
                </v-select>
                <v-text-field
                  class="flex-grow-1"
                  style="border-radius: 0 28px 28px 0; width: 0"
                  v-model.number="preset.font.fontSize"
                  :label="$t('fontSize')"
                  type="number"
                  :min="25"
                  :max="150"
                  step="1"
                  rounded
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </div>
              <v-menu
                offset-y
                transition="slide-y-transition"
                rounded="lg"
                :close-on-content-click="false"
                :max-width="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="preset.colors.secondaryColor"
                    readonly
                    :label="$t('secondaryColor')"
                    rounded
                    filled
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    class="mb-2"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon :color="preset.colors.secondaryColor" class="mr-2">mdi-circle</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-color-picker v-model="preset.colors.secondaryColor" mode="hexa"></v-color-picker>
              </v-menu>

              <div class="d-flex mb-4">
                <v-menu
                  offset-y
                  transition="slide-y-transition"
                  rounded="lg"
                  :close-on-content-click="false"
                  :max-width="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="flex-grow-1"
                      style="border-radius: 28px 0 0 28px; width: 0"
                      v-model="preset.colors.outlineColor"
                      readonly
                      :label="$t('outlineColor')"
                      rounded
                      filled
                      dense
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:prepend-inner>
                        <v-icon :color="preset.colors.outlineColor" class="mr-2">mdi-circle</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-color-picker v-model="preset.colors.outlineColor" mode="hexa"></v-color-picker>
                </v-menu>
                <v-text-field
                  class="flex-grow-1"
                  style="border-radius: 0 28px 28px 0; width: 0"
                  v-model.number="preset.font.fontStrokeSize"
                  :disabled="preset.enableBackgroundBox"
                  :label="$t('strokeSize')"
                  type="number"
                  :min="0"
                  :max="50"
                  step="1"
                  rounded
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </div>

              <h4 class="text--primary">
                {{ $t("otherSettings") }}
              </h4>
              <div class="d-flex align-center justify-space-between my-2">
                <div class="d-flex align-center">
                  <v-icon class="mr-2">mdi-format-letter-case-upper</v-icon>
                  <span class="subtitle-1">{{ $t("enableUppercase") }}</span>
                </div>
                <v-switch
                  v-model="preset.enableUppercase"
                  inset
                  dense
                  color="primary"
                  hide-details
                  class="ma-0"
                ></v-switch>
              </div>
              <div class="d-flex align-center justify-space-between my-2">
                <div class="d-flex align-center">
                  <v-icon class="mr-2">mdi-text-box</v-icon>
                  <span class="subtitle-1">{{ $t("enableBackgroundBox") }}</span>
                </div>
                <v-switch
                  v-model="preset.enableBackgroundBox"
                  :disabled="preset.wordHighlight === 'background'"
                  inset
                  dense
                  color="primary"
                  hide-details
                  class="ma-0"
                ></v-switch>
              </div>
              <div class="d-flex align-center justify-space-between my-2">
                <div class="d-flex align-center">
                  <v-icon class="mr-2">mdi-eye-off</v-icon>
                  <span class="subtitle-1">{{ $t("enableWordReveal") }}</span>
                </div>
                <v-switch
                  v-model="preset.enableWordReveal"
                  inset
                  dense
                  color="primary"
                  hide-details
                  class="ma-0"
                ></v-switch>
              </div>
            </v-form>
          </div>
        </template>
      </v-skeleton-loader>
    </v-card-text>
    <v-card-actions v-if="screen !== 'presets'" class="pt-2 px-6 py-4">
      <v-btn
        v-if="screen === 'preset'"
        depressed
        rounded
        :loading="removePresetLoading"
        @click="removePreset(preset._id)"
        ><v-icon>mdi-delete-outline</v-icon></v-btn
      >
      <v-btn
        color="primary"
        depressed
        rounded
        @click="savePreset(preset)"
        :disabled="!valid || isChanged"
        :loading="savePresetLoading"
        class="flex-grow-1"
        >{{ $t("save") }}</v-btn
      >
    </v-card-actions>
    <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useSubtitlesPresetStore } from "@/stores/subtitles-preset";
import { useEditStore } from "@/stores/edit";
import { useSnackbarStore } from "@/stores/snackbar";
import { deepCopy } from "@/util/helpers";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default {
  name: "SubtitlesPresetsCard",
  components: { ConfirmDialog },
  setup() {
    const accountStore = useAccountStore();
    const subtitlesPresetStore = useSubtitlesPresetStore();
    const editStore = useEditStore();
    const snackbarStore = useSnackbarStore();
    return { accountStore, subtitlesPresetStore, editStore, snackbarStore };
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t("requiredField"),
      },
      valid: true,
      loading: true,
      loadingPresetId: null,
      savePresetLoading: false,
      removePresetLoading: false,
      screen: "presets",
      preset: {},
      transitions: [
        { value: "none", text: this.$t("none") },
        { value: "subtitle-bounce", text: this.$t("blockBounce") },
        { value: "subtitle-slideup", text: this.$t("blockSlideup") },
        { value: "word-bounce", text: this.$t("wordBounce") },
        { value: "word-slideup", text: this.$t("wordSlideup") },
      ],
      wordHighlights: [
        { value: "none", text: this.$t("none") },
        { value: "current-only", text: this.$t("currentOnly") },
        { value: "upto-current", text: this.$t("upToCurrent") },
        { value: "background", text: this.$t("background") },
      ],
      splittings: [
        { value: 1, text: this.$t("oneWord") },
        { value: 10, text: this.$t("nLetters", { letters: 10 }) },
        { value: 15, text: this.$t("nLetters", { letters: 15 }) },
        { value: 20, text: this.$t("nLetters", { letters: 20 }) },
        { value: 25, text: this.$t("nLetters", { letters: 25 }) },
        { value: 30, text: this.$t("nLetters", { letters: 30 }) },
        { value: 999, text: this.$t("none") },
      ],
    };
  },
  async created() {
    try {
      if (this.accountStore.isLoggedIn && !this.subtitlesPresetStore.lastSynced)
        await this.subtitlesPresetStore.fetchSubtitlesPresets();
    } catch (err) {
      console.log(err);
      this.snackbarStore.text = this.$t("failedToLoadCustomSubtitlesPresets");
      this.snackbarStore.snackbar = true;
    }
    this.loading = false;
  },
  methods: {
    initNewPreset() {
      this.preset = { ...deepCopy(this.editStore.subtitlesConfig), name: "" };
      this.screen = "newPreset";
    },
    async viewPreset(presetId) {
      try {
        this.loadingPresetId = presetId;
        const resp = await this.subtitlesPresetStore.fetchSubtitlesPreset(presetId);
        const {
          // eslint-disable-next-line no-unused-vars
          blockVerticalPosition,
          ...subtitlesConfigRest
        } = this.editStore.subtitlesConfig;

        this.preset = {
          ...deepCopy(subtitlesConfigRest),
          ...deepCopy(resp.data.subtitlesPreset),
        };
        this.screen = "preset";
      } catch (err) {
        console.log(err);
        this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      }
      this.loadingPresetId = null;
    },
    async savePreset(preset) {
      try {
        this.savePresetLoading = true;

        if (preset._id) await this.subtitlesPresetStore.updateSubtitlesPreset(preset);
        else await this.subtitlesPresetStore.createNewSubtitlesPreset(preset);

        this.screen = "presets";
      } catch (err) {
        console.log(err);
        this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      }
      this.savePresetLoading = false;
    },
    async removePreset(presetId) {
      try {
        if (await this.$refs.confirm.open(this.$t("pleaseConfirm"), this.$t("areYouSureYouWantToRemoveThisPreset"))) {
          this.removePresetLoading = true;
          await this.subtitlesPresetStore.removeSubtitlesPreset(presetId);
          this.screen = "presets";
        }
      } catch (err) {
        console.log(err);
        this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      }
      this.removePresetLoading = false;
    },
    stringifyWithSortedKeys(obj) {
      const sorter = (key, value) => {
        if (typeof value === "object" && !Array.isArray(value) && value !== null) {
          const sorted = {};
          Object.keys(value)
            .sort()
            .forEach((k) => {
              sorted[k] = value[k];
            });
          return sorted;
        }
        return value;
      };

      return JSON.stringify(obj, sorter);
    },
  },
  computed: {
    isChanged() {
      try {
        const preset = this.subtitlesPresetStore.subtitlesPresets.find((e) => e._id === this.preset._id);
        return this.stringifyWithSortedKeys(preset) === this.stringifyWithSortedKeys(this.preset);
      } catch {
        return false;
      }
    },
  },
};
</script>

<style scoped>
:deep(.v-input--switch--inset .v-input--selection-controls__input) {
  width: 38px;
}
</style>
