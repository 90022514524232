import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#0169EE",
        accent: "#FF9C00",
        grey: '#f0f2f4',
      },
      dark: {
        primary: "#0169EE",
        accent: "#FF9C00",
        grey: '#f0f2f4',
      },
    },
  },
  lang: {
    locales: { en },
    current: 'en',
  },
});
