<template>
  <div>
    <v-container :class="$vuetify.breakpoint.mobile ? 'mt-4' : 'mt-8'">
      <h1>{{ $t("logInTitle") }}</h1>
      <p>{{ $t("logInDescription") }}</p>
      <div id="google_btn"></div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "LoginView",
  mounted() {
    const googleSingInButton = document.getElementById("google_btn");
    // eslint-disable-next-line no-undef
    google.accounts.id.renderButton(googleSingInButton, {
      theme: "filled_blue",
      shape: "pill",
    });
  },
};
</script>