import Router from "vue-router";
import { useAccountStore } from "@/stores/account";
import Login from "@/views/auth/Login.vue";
// import Register from "@/views/auth/Register.vue";
// import Verification from "@/views/auth/Verification.vue";

const ifAuthenticated = (to, from, next) => {
  const store = useAccountStore()
  if (store.isLoggedIn) return next();
  if (to.query.viewPlans === "true") localStorage.setItem("viewPlans", "true");
  return next("/login");
};

// const ifAuthenticatedAndVerified = (to, from, next) => {
//   const store = useAccountStore()
//   if (store.isLoggedIn && store.isVerified) return next();
//   if (!store.isLoggedIn) return next("/login");
//   if (!store.isVerified) return next("/verification");
// };

const routes = [
  // {
  //   path: "/register",
  //   name: "register",
  //   component: Register,
  //   meta: {
  //     title: "Register 2short.ai account"
  //   }
  // },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Login in to your 2short.ai account"
    }
  },
  // {
  //   path: "/forgot-password",
  //   name: "forgot-password",
  //   component: () => import(/* webpackChunkName: "forgot-password" */ "@/views/auth/ForgotPassword.vue"),
  //   meta: {
  //     title: "2short.ai - Forgotten password?"
  //   }
  // },
  // {
  //   path: "/change-password",
  //   name: "change-password",
  //   component: () => import(/* webpackChunkName: "change-password" */ "@/views/auth/ChangePassword.vue"),
  //   meta: {
  //     title: "2short.ai - Change password"
  //   }
  // },
  // {
  //   path: "/verification",
  //   name: "verification",
  //   component: Verification,
  //   beforeEnter: ifAuthenticated,
  //   meta: {
  //     title: "Verify your 2short.ai account"
  //   }
  // },
  {
    path: "/account",
    name: "account",
    component: () => import(/* webpackChunkName: "account" */ "@/views/Account.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      title: "Your 2short.ai account"
    }
  },
  {
    path: "/",
    name: "search",
    component: () => import(/* webpackChunkName: "search" */ "@/views/Search.vue"),
    meta: {
      title: "Create YT Shorts in seconds with 2short.ai"
    }
  },
  {
    path: "/shorts",
    name: "shorts",
    component: () => import(/* webpackChunkName: "shorts" */ "@/views/Shorts.vue"),
  },
  {
    path: "/edit/:shortId",
    name: "edit",
    component: () => import(/* webpackChunkName: "edit" */ "@/views/Edit.vue"),
  },
  {
    path: "*",
    redirect: { name: "search" },
  }
]

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router
