import { defineStore } from "pinia"
import { useEditStore } from "@/stores/edit.js";
import axios from "axios"
import { API_URL } from "../api.js"

export const useShortStore = defineStore("short", {
    getters: {
        youtubeVideoId: state => state.transcript.youtubeVideoId || state.short.youtubeVideoId,
        shortId: state => state.short._id,
        shortLanguage: state => state.shortTranscript.transcriptLanguage
    },
    state: () => ({
        transcript: {},
        shorts: [],
        short: {},
        shortDetail: false,
        language: "en",
        editSubtitles: false,
        shortTranscript: {},
        editedShortTranscript: null,
        editedShortTranscriptWords: null,
        searchValue: null,
    }),
    actions: {
        resetShortsHighlight() {
            this.shorts.forEach(short => {
                short.highlight = null
            })
        },
        async fetchShorts(youtubeVideoId) {
            try {
                const resp = await axios.get(`${API_URL}/shorts?youtubeVideoId=${youtubeVideoId}&language=${this.language}`, { withCredentials: true })
                this.transcript = resp.data.transcript
                const sortedShorts = resp.data.shorts.slice().sort((a, b) => (b.viralityScore || 0) - (a.viralityScore || 0))
                for (let i = 0; i < sortedShorts.length && i < 3; i++) {
                    const short = sortedShorts[i];
                    if (short.viralityScore) sortedShorts[i].highlight = i + 1;
                    else break;
                }
                this.searchValue = null
                this.shorts = sortedShorts
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchShort(shortId) {
            try {
                const editStore = useEditStore();
                editStore.fetchingShort = true;
                const resp = await axios.get(`${API_URL}/shorts/${shortId}`, { withCredentials: true })
                this.short = resp.data.short
                editStore.fetchingShort = false;
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchShortTranscript(shortId) {
            try {
                const editStore = useEditStore();
                editStore.fetchingTranscript = true;
                const resp = await axios.get(`${API_URL}/shorts/${shortId}/transcript`, { withCredentials: true })
                this.shortTranscript = resp.data.short
                this.editedShortTranscript = resp.data.short.transcript
                this.editedShortTranscriptWords = editStore.transcriptToTranscriptWords(resp.data.short.transcript)
                editStore.fetchingTranscript = false;
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async generateShorts(youtubeVideoId) {
            try {
                const resp = await axios.post(`${API_URL}/shorts/generate`, { youtubeVideoId, language: this.language }, { withCredentials: true })
                this.transcript = resp.data.transcript
                this.resetShortsHighlight()
                const sortedShorts = resp.data.shorts.slice().sort((a, b) => (b.viralityScore || 0) - (a.viralityScore || 0))
                for (let i = 0; i < sortedShorts.length && i < 3; i++) {
                    const short = sortedShorts[i];
                    if (short.viralityScore) sortedShorts[i].highlight = i + 1;
                    else break;
                }
                this.shorts.push(...sortedShorts)
                return resp
            }
            catch (error) {
                console.log(error)
                throw error.response.data
            }
        },
    },
})