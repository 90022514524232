<template>
  <SubtitlesPresetsBottomSheet v-if="$vuetify.breakpoint.smAndDown" />
  <SubtitlesPresetsDialog v-else />
</template>

<script>
import SubtitlesPresetsBottomSheet from "@/components/edit/SubtitlesPresetsBottomSheet.vue";
import SubtitlesPresetsDialog from "@/components/edit/SubtitlesPresetsDialog.vue";

export default {
  name: "SubtitlesPresets",
  components: {
    SubtitlesPresetsBottomSheet,
    SubtitlesPresetsDialog,
  },
};
</script>