<template>
  <v-bottom-sheet
    inset
    max-width="400px"
    scrollable
    v-model="feedbackStore.feedback"
  >
    <FeedbackCard />
  </v-bottom-sheet>
</template>

<script>
import { useFeedbackStore } from "@/stores/feedback";
import FeedbackCard from "@/components/feedback/FeedbackCard.vue";

export default {
  name: "FeedbackBottomSheet",
  components: {
    FeedbackCard,
  },
  setup() {
    const feedbackStore = useFeedbackStore();
    return { feedbackStore };
  },
};
</script>

<style scoped>
:deep(.v-bottom-sheet),
:deep(.v-bottom-sheet .v-card) {
  border-radius: 16px 16px 0 0;
}
</style>