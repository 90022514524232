<template>
  <v-bottom-sheet inset max-width="600px" scrollable v-model="editStore.subtitlesConfigBottomSheet">
    <v-card>
      <v-card-title
        class="font-weight-bold d-flex align-center justify-space-between"
      >
        <div class="d-flex align-center">
          <v-btn
            icon
            @click="
              subtitlesEditMounted = true;
              editStore.editSubtitles = true;
            "
            class="mr-2"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <span>{{ $t("subtitles") }}</span>
        </div>
        <v-switch
          v-model="editStore.enableSubtitles"
          inset
          dense
          hide-details
          class="ma-0 pa-0"
        ></v-switch>
      </v-card-title>
      <v-card-text>
        <SubtitlesConfig :mountSubtitlesEdit="subtitlesEditMounted" />
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { useEditStore } from "@/stores/edit";
import SubtitlesConfig from "@/components/edit/subtitles/SubtitlesConfig.vue";

export default {
  name: "SubtitlesConfigBottomSheet",
  components: {
    SubtitlesConfig,
  },
  setup() {
    const editStore = useEditStore();
    return { editStore };
  },
  data() {
    return { subtitlesEditMounted: false };
  },
};
</script>

<style scoped>
:deep(.v-bottom-sheet),
:deep(.v-bottom-sheet .v-card) {
  border-radius: 16px 16px 0 0;
}

:deep(.v-input--switch--inset .v-input--selection-controls__input) {
  width: 38px;
}

:deep(.v-input--selection-controls__input) {
  margin-right: 4px !important;
}
</style>
