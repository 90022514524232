import { defineStore } from "pinia"
import axios from "axios"
import { API_URL } from "../api.js"

export const useFeedbackStore = defineStore("feedback", {
    state: () => ({
        feedback: false,
        tag: "",
        message: ""
    }),
    actions: {
        async sendFeedback() {
            try {
                const resp = await axios.post(`${API_URL}/feedback`, { tag: this.tag, message: this.message }, { withCredentials: true })
                this.feedback = false;
                this.tag = ""
                this.message = ""
                localStorage.setItem("lastFeedbackTimestamp", Date.now())
                return resp
            }
            catch (error) {
                console.log(error)
                throw error.response.data
            }
        },
    }
})