<template>
  <div class="d-flex justify-center align-center controls-wrapper">
    <v-menu offset-y transition="slide-y-reverse-transition" rounded="lg" top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-show="!editStore.selectedLayer" icon v-bind="attrs" v-on="on">
          <v-icon v-if="editStore.aspectRatio === '9:16'">mdi-crop-portrait</v-icon>
          <v-icon v-if="editStore.aspectRatio === '1:1'">mdi-crop-square</v-icon>
          <v-icon v-if="editStore.aspectRatio === '16:9'">mdi-crop-landscape</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item-group mandatory v-model="editStore.aspectRatio" color="primary">
          <v-list-item
            v-for="item in [
              { value: '9:16', text: $t('portrait') },
              { value: '1:1', text: $t('square') },
              { value: '16:9', text: $t('landscape') },
            ]"
            :key="item.value"
            :value="item.value"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-badge overlap avatar color="transparent">
      <template v-slot:badge>
        <v-progress-circular
          v-if="editStore.fetchingTranscript"
          indeterminate
          size="14"
          width="2"
        ></v-progress-circular>
      </template>

      <v-btn
        icon
        v-show="!editStore.selectedLayer"
        @click="
          editStore.subtitlesConfigBottomSheet = true;
          fetchSubtitlesPresets();
        "
        :disabled="editStore.fetchingTranscript"
      >
        <v-icon>mdi-subtitles</v-icon>
      </v-btn>
    </v-badge>

    <v-btn
      icon
      v-show="editStore.selectedLayer && editStore.selectedLayer.metadata.id.startsWith('layer-')"
      :input-value="editStore.cropping"
      @click="editStore.cropping ? (editStore.cropping = false) : editStore.runLayerCrop++"
    >
      <v-icon>mdi-crop</v-icon>
    </v-btn>
    <!-- <v-btn
      icon
      v-show="
        editStore.selectedLayer &&
        editStore.selectedLayer.metadata.id.startsWith('layer-')
      "
    >
      <v-icon>mdi-content-cut</v-icon>
    </v-btn> -->
    <v-btn icon v-show="editStore.selectedLayer" @click="editStore.runRemoveLayer++">
      <v-icon>mdi-delete</v-icon>
    </v-btn>

    <v-menu offset-y transition="slide-y-reverse-transition" rounded="lg" top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click.once="
            !accountStore.isLoggedIn || editStore.brandAssetsMounted ? null : brandAssetStore.fetchBrandAssets()
          "
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list dense subheader>
          <v-subheader>{{ $t("actions") }}</v-subheader>

          <v-list-item @click="editStore.runAddLayer++">
            <v-list-item-icon>
              <v-icon>mdi-layers-plus</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("addNewLayer") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              editStore.brandAssetsMounted = true;
              brandAssetStore.manageAssets = true;
            "
            :two-line="!accountStore.isLoggedIn"
            :disabled="!accountStore.isLoggedIn || brandAssetStore.syncing"
          >
            <v-list-item-icon>
              <v-icon v-if="!brandAssetStore.syncing" :disabled="!accountStore.isLoggedIn">mdi-folder-heart</v-icon>
              <v-progress-circular v-else indeterminate size="20" disabled></v-progress-circular>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("manageBrandAssets") }}</v-list-item-title>
              <v-list-item-subtitle v-if="!accountStore.isLoggedIn" class="caption">{{
                $t("accountRequired")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div v-if="brandAssetStore.brandAssets.length > 0">
          <v-divider inset></v-divider>

          <v-list dense subheader>
            <v-subheader>
              {{ $t("brandAssets") }}
            </v-subheader>

            <v-list-item-group v-model="editStore.brandAssetsUrls" multiple color="primary">
              <v-list-item
                v-for="item in brandAssetStore.brandAssets.map((e) => ({
                  value: e.file.url,
                  text: e.file.name,
                }))"
                :key="item.value"
                :value="item.value"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-card>
    </v-menu>

    <v-badge overlap avatar color="transparent">
      <template v-slot:badge>
        <v-progress-circular
          @click="editStore.abortControllerAutoCrop?.abort()"
          v-if="editStore.faceDetecting"
          :indeterminate="editStore.progress <= 5"
          :value="editStore.progress"
          :rotate="editStore.progress <= 5 ? null : -90"
          size="14"
          width="2"
        ></v-progress-circular>
      </template>

      <v-btn
        v-show="!editStore.selectedLayer"
        icon
        :disabled="editStore.cropping || editStore.faceDetecting || editStore.exporting"
        @click="editStore.runAutoCrop++"
      >
        <v-icon>mdi-auto-fix</v-icon>
      </v-btn>
    </v-badge>

    <SubtitlesConfigBottomSheet />
  </div>
</template>

<script>
import SubtitlesConfigBottomSheet from "@/components/edit/subtitles/SubtitlesConfigBottomSheet.vue";
import { useEditStore } from "@/stores/edit";
import { useAccountStore } from "@/stores/account";
import { useBrandAssetStore } from "@/stores/brand-asset";
import { useSubtitlesPresetStore } from "@/stores/subtitles-preset";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "EditorControlsMobile",
  components: {
    SubtitlesConfigBottomSheet,
  },
  setup() {
    const editStore = useEditStore();
    const accountStore = useAccountStore();
    const brandAssetStore = useBrandAssetStore();
    const subtitlesPresetStore = useSubtitlesPresetStore();
    const snackbarStore = useSnackbarStore();
    return { editStore, accountStore, brandAssetStore, subtitlesPresetStore, snackbarStore };
  },
  methods: {
    async fetchSubtitlesPresets() {
      try {
        if (this.accountStore.isLoggedIn && !this.subtitlesPresetStore.lastSynced)
          await this.subtitlesPresetStore.fetchSubtitlesPresets();
      } catch {
        this.snackbarStore.text = this.$t("failedToLoadCustomSubtitlesPresets");
        this.snackbarStore.snackbar = true;
      }
    },
  },
};
</script>

<style scoped>
.controls-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 56px;
  width: 100%;
  gap: 24px;
}

.theme--dark .controls-wrapper {
  background: linear-gradient(0deg, #121212 25%, hsla(0, 0%, 7%, 0.8) 75%, hsla(0, 0%, 7%, 0));
}

.theme--light .controls-wrapper {
  background: linear-gradient(0deg, #fff 25%, hsla(0, 0%, 100%, 0.85) 75%, hsla(0, 0%, 100%, 0));
}
</style>
