import { defineStore } from "pinia";
import axios from "axios";
import { API_URL } from "../api.js";

export const useBrandAssetStore = defineStore("brandAsset", {
  state: () => ({
    brandAssets: [
      // {
      //   id: "dog",
      //   file: {
      //     name: "dog.png",
      //     url: "https://placehold.co/300x300?text=Dog",
      //   },
      // },
      // {
      //   id: "cat",
      //   file: {
      //     name: "cat.png",
      //     url: "https://placehold.co/600x400?text=Cat",
      //   },
      // },
      // {
      //   id: "opus",
      //   file: {
      //     name: "opus.png",
      //     url: "https://2shortai-dev.fra1.cdn.digitaloceanspaces.com/opus.png",
      //   },
      // },
    ],
    manageAssets: false,
    syncing: false,
    lastSynced: null,
  }),
  actions: {
    async uploadBrandAsset(file) {
      try {
        const resp = await axios.post(
          `${API_URL}/brand-assets`,
          { file },
          { withCredentials: true }
        );
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchBrandAssets() {
      try {
        this.syncing = true;
        const resp = await axios.get(`${API_URL}/brand-assets`, {
          withCredentials: true,
        });
        const data = resp.data;

        data.brandAssets.forEach((brandAsset) => {
          const brandAssetIndex = this.brandAssets.findIndex(
            (e) => e._id === brandAsset._id
          );
          if (brandAssetIndex === -1) this.brandAssets.push(brandAsset);
        });

        this.lastSynced = Date.now();
        this.syncing = false;

        return resp;
      } catch (error) {
        console.log(error);
        this.syncing = false;
        throw error.response.data;
      }
    },
    async removeBrandAsset(brandAssetId) {
      try {
        const resp = await axios.delete(
          `${API_URL}/brand-assets/${brandAssetId}`,
          { withCredentials: true }
        );
        const brandAssetIndex = this.brandAssets.findIndex(
          (e) => e._id === brandAssetId
        );
        if (brandAssetIndex > -1) this.brandAssets.splice(brandAssetIndex, 1);
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
