<template>
  <v-card>
    <v-card-title class="font-weight-bold d-flex align-center justify-space-between">
      <span>{{ $t("editSubtitles") }}</span>
      <v-btn icon @click="editStore.editSubtitles = false">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader
        :loading="loading"
        type="list-item-two-line, list-item-three-line, list-item-three-line"
        class="pa-0"
      >
        <template v-slot:default>
          <v-list dense class="pa-0">
            <v-list-item v-for="(line, i) in transcript" :key="i">
              <v-list-item-icon class="text--secondary">
                <span class="mt-1">{{ transcriptLineStartTime(line.start) }}</span>
              </v-list-item-icon>
              <v-list-item-content>
                <bubble-menu
                  class="bubble-menu"
                  :editor="editors[i]"
                  :tippy-options="{ duration: 100 }"
                  :shouldShow="() => true"
                >
                  <v-btn icon small dark @click="editors[i].chain().focus().unsetColor().run()">
                    <v-icon>mdi-circle-outline</v-icon>
                  </v-btn>
                  <v-btn
                    :color="subtitlesPresetHighlightColors.primaryHighlightColor || '#04F827FF'"
                    icon
                    small
                    @click="
                      editors[i].chain().focus().setColor(subtitlesPresetHighlightColors.primaryHighlightColor).run()
                    "
                  >
                    <v-icon>mdi-circle</v-icon>
                  </v-btn>
                  <v-btn
                    :color="subtitlesPresetHighlightColors.secondaryHighlightColor || '#FFFD03FF'"
                    icon
                    small
                    @click="
                      editors[i].chain().focus().setColor(subtitlesPresetHighlightColors.secondaryHighlightColor).run()
                    "
                  >
                    <v-icon>mdi-circle</v-icon>
                  </v-btn>
                  <!-- <v-menu
                    right
                    top
                    offset-y
                    transition="slide-y-transition"
                    rounded="lg"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small dark v-bind="attrs" v-on="on">
                        <v-icon>mdi-emoticon-outline</v-icon>
                      </v-btn>
                    </template>
                    <EmojiPicker @emoji_click="editors[i].chain().focus().insertContent($event).run()" />
                  </v-menu> -->
                </bubble-menu>
                <editor-content :editor="editors[i]" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-skeleton-loader>
    </v-card-text>
    <v-card-actions class="pt-0 px-6 pb-4">
      <v-btn color="primary" depressed rounded block @click="saveEditedTranscript()" :disabled="loading || isChanged">{{
        $t("save")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
import Document from "@tiptap/extension-document";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
// import EmojiPicker from "@/components/EmojiPicker.vue";
import { useShortStore } from "@/stores/short";
import { useSubtitlesPresetStore } from "@/stores/subtitles-preset";
import { useEditStore } from "@/stores/edit.js";
import { useSnackbarStore } from "@/stores/snackbar";
import { convertSeconds, deepCopy } from "@/util/helpers";
import { debounce } from "@/util/helpers";

export default {
  name: "SubtitlesEditCard",
  components: {
    EditorContent,
    BubbleMenu,
    // EmojiPicker,
  },
  setup() {
    const shortStore = useShortStore();
    const subtitlesPresetStore = useSubtitlesPresetStore();
    const editStore = useEditStore();
    const snackbarStore = useSnackbarStore();
    return { shortStore, subtitlesPresetStore, editStore, snackbarStore };
  },
  data() {
    return { loading: true, transcript: null, editors: [] };
  },
  watch: {
    "subtitlesPresetStore.subtitlesPreset"() {
      this.loadSubtitlesPreset(this.subtitlesPresetStore.subtitlesPreset);
    },
  },
  async created() {
    const CustomDocument = Document.extend({
      content: "block*",
    });

    if (this.shortStore.shortTranscript._id !== this.shortStore.shortId)
      await this.shortStore.fetchShortTranscript(this.shortStore.shortId);

    this.transcript = deepCopy(this.shortStore.editedShortTranscript);

    this.transcript.forEach((e, i) => {
      const extensions = [
        CustomDocument,
        StarterKit.configure({
          document: false,
        }),
        TextStyle,
        Color,
      ];

      this.editors[i] = new Editor({
        content: e.html || e.text.replace(/\n/g, "<br/>"),
        extensions,
        onUpdate: debounce(({ editor }) => {
          this.transcript[i].text = editor.getText({ blockSeparator: "\n" });
          this.$set(this.transcript[i], "html", editor.getHTML());
        }, 500),
      });
    });

    this.loadSubtitlesPreset(this.subtitlesPresetStore.subtitlesPreset);

    this.loading = false;
  },
  beforeDestroy() {
    this.editors.forEach((editor) => {
      editor.destroy();
    });
  },
  methods: {
    async loadSubtitlesPreset(subtitlesPresetId) {
      try {
        if (
          [
            "whiteTextWithBlackOutline",
            "whiteTextWithBlackOutlineUppercase",
            "blackTextWithWhiteOutline",
            "blackTextWithWhiteOutlineUppercase",
            "whiteTextOnBlackBackground",
            "whiteTextOnBlackBackgroundUppercase",
            "blackTextOnWhiteBackground",
            "blackTextOnWhiteBackgroundUppercase",
          ].includes(subtitlesPresetId)
        )
          return;

        const subtitlesPreset = this.subtitlesPresetStore.subtitlesPresets.find((e) => e._id === subtitlesPresetId);
        if (!subtitlesPreset || Object.keys(subtitlesPreset).length <= 2)
          await this.subtitlesPresetStore.fetchSubtitlesPreset(subtitlesPresetId);
      } catch (err) {
        console.log(err);
        this.snackbarStore.text = this.$t("somethingWentWrong");
        this.snackbarStore.snackbar = true;
      }
    },
    transcriptLineStartTime(transcriptLineStart) {
      const startSeconds = parseFloat(transcriptLineStart) - parseFloat(this.transcript[0].start);
      return convertSeconds(startSeconds, true);
    },
    saveEditedTranscript() {
      this.shortStore.editedShortTranscript = deepCopy(this.transcript);
      this.shortStore.editedShortTranscriptWords = this.editStore.transcriptToTranscriptWords(
        deepCopy(this.transcript),
        this.editStore.subtitlesConfig.maxCharsPerBlock
      );
      this.editStore.editSubtitles = false;
    },
  },
  computed: {
    subtitlesPresetHighlightColors() {
      switch (this.subtitlesPresetStore.subtitlesPreset) {
        case "whiteTextWithBlackOutline":
        case "whiteTextWithBlackOutlineUppercase":
        case "blackTextWithWhiteOutline":
        case "blackTextWithWhiteOutlineUppercase":
        case "whiteTextOnBlackBackground":
        case "whiteTextOnBlackBackgroundUppercase":
        case "blackTextOnWhiteBackground":
        case "blackTextOnWhiteBackgroundUppercase":
          return {
            primaryHighlightColor: "#04F827FF",
            secondaryHighlightColor: "#FFFD03FF",
          };
        default:
          try {
            return this.subtitlesPresetStore.subtitlesPresets.find(
              (e) => e._id === this.subtitlesPresetStore.subtitlesPreset
            );
          } catch {
            return {
              primaryHighlightColor: "#04F827FF",
              secondaryHighlightColor: "#FFFD03FF",
            };
          }
      }
    },
    isChanged() {
      try {
        return JSON.stringify(this.transcript) === JSON.stringify(this.shortStore.editedShortTranscript);
      } catch {
        return false;
      }
    },
  },
};
</script>

<style scoped>
:deep(.v-text-field--rounded > .v-input__control > .v-input__slot) {
  padding: 0;
}

:deep(.tiptap p) {
  font-family: "Roboto", "Noto Color Emoji", sans-serif;
  margin: 0;
  font-size: 16px;
  line-height: 1.75rem;
}

:deep(.tiptap) {
  outline: none;
}

.v-list-item__content > *:not(:last-child) {
  margin-bottom: 0;
}

.bubble-menu {
  display: flex;
  background-color: black;
  padding: 2px 12px;
  border-radius: 24px;
}
</style>