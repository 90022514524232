<template>
  <FeedbackBottomSheet v-if="$vuetify.breakpoint.smAndDown" />
  <FeedbackDialog v-else />
</template>

<script>
import FeedbackBottomSheet from "@/components/feedback/FeedbackBottomSheet.vue";
import FeedbackDialog from "@/components/feedback/FeedbackDialog.vue";

export default {
  name: "FeedbackComponent",
  components: {
    FeedbackBottomSheet,
    FeedbackDialog,
  },
};
</script>