var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center align-center controls-wrapper"},[_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-reverse-transition","rounded":"lg","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.editStore.selectedLayer),expression:"!editStore.selectedLayer"}],attrs:{"icon":""}},'v-btn',attrs,false),on),[(_vm.editStore.aspectRatio === '9:16')?_c('v-icon',[_vm._v("mdi-crop-portrait")]):_vm._e(),(_vm.editStore.aspectRatio === '1:1')?_c('v-icon',[_vm._v("mdi-crop-square")]):_vm._e(),(_vm.editStore.aspectRatio === '16:9')?_c('v-icon',[_vm._v("mdi-crop-landscape")]):_vm._e()],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"mandatory":"","color":"primary"},model:{value:(_vm.editStore.aspectRatio),callback:function ($$v) {_vm.$set(_vm.editStore, "aspectRatio", $$v)},expression:"editStore.aspectRatio"}},_vm._l(([
            { value: '9:16', text: _vm.$t('portrait') },
            { value: '1:1', text: _vm.$t('square') },
            { value: '16:9', text: _vm.$t('landscape') },
          ]),function(item){return _c('v-list-item',{key:item.value,attrs:{"value":item.value}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1),_c('v-badge',{attrs:{"overlap":"","avatar":"","color":"transparent"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [(_vm.editStore.fetchingTranscript)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"14","width":"2"}}):_vm._e()]},proxy:true}])},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editStore.selectedLayer),expression:"!editStore.selectedLayer"}],attrs:{"icon":"","disabled":_vm.editStore.fetchingTranscript},on:{"click":function($event){_vm.editStore.subtitlesConfigBottomSheet = true;
        _vm.fetchSubtitlesPresets();}}},[_c('v-icon',[_vm._v("mdi-subtitles")])],1)],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.editStore.selectedLayer && _vm.editStore.selectedLayer.metadata.id.startsWith('layer-')),expression:"editStore.selectedLayer && editStore.selectedLayer.metadata.id.startsWith('layer-')"}],attrs:{"icon":"","input-value":_vm.editStore.cropping},on:{"click":function($event){_vm.editStore.cropping ? (_vm.editStore.cropping = false) : _vm.editStore.runLayerCrop++}}},[_c('v-icon',[_vm._v("mdi-crop")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.editStore.selectedLayer),expression:"editStore.selectedLayer"}],attrs:{"icon":""},on:{"click":function($event){_vm.editStore.runRemoveLayer++}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-reverse-transition","rounded":"lg","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"~click":function($event){!_vm.accountStore.isLoggedIn || _vm.editStore.brandAssetsMounted ? null : _vm.brandAssetStore.fetchBrandAssets()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('v-card',[_c('v-list',{attrs:{"dense":"","subheader":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("actions")))]),_c('v-list-item',{on:{"click":function($event){_vm.editStore.runAddLayer++}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-layers-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("addNewLayer")))])],1)],1),_c('v-list-item',{attrs:{"two-line":!_vm.accountStore.isLoggedIn,"disabled":!_vm.accountStore.isLoggedIn || _vm.brandAssetStore.syncing},on:{"click":function($event){_vm.editStore.brandAssetsMounted = true;
            _vm.brandAssetStore.manageAssets = true;}}},[_c('v-list-item-icon',[(!_vm.brandAssetStore.syncing)?_c('v-icon',{attrs:{"disabled":!_vm.accountStore.isLoggedIn}},[_vm._v("mdi-folder-heart")]):_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","disabled":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("manageBrandAssets")))]),(!_vm.accountStore.isLoggedIn)?_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("accountRequired")))]):_vm._e()],1)],1)],1),(_vm.brandAssetStore.brandAssets.length > 0)?_c('div',[_c('v-divider',{attrs:{"inset":""}}),_c('v-list',{attrs:{"dense":"","subheader":""}},[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$t("brandAssets"))+" ")]),_c('v-list-item-group',{attrs:{"multiple":"","color":"primary"},model:{value:(_vm.editStore.brandAssetsUrls),callback:function ($$v) {_vm.$set(_vm.editStore, "brandAssetsUrls", $$v)},expression:"editStore.brandAssetsUrls"}},_vm._l((_vm.brandAssetStore.brandAssets.map((e) => ({
                value: e.file.url,
                text: e.file.name,
              }))),function(item){return _c('v-list-item',{key:item.value,attrs:{"value":item.value},scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]}}],null,true)})}),1)],1)],1):_vm._e()],1)],1),_c('v-badge',{attrs:{"overlap":"","avatar":"","color":"transparent"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [(_vm.editStore.faceDetecting)?_c('v-progress-circular',{attrs:{"indeterminate":_vm.editStore.progress <= 5,"value":_vm.editStore.progress,"rotate":_vm.editStore.progress <= 5 ? null : -90,"size":"14","width":"2"},on:{"click":function($event){_vm.editStore.abortControllerAutoCrop?.abort()}}}):_vm._e()]},proxy:true}])},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editStore.selectedLayer),expression:"!editStore.selectedLayer"}],attrs:{"icon":"","disabled":_vm.editStore.cropping || _vm.editStore.faceDetecting || _vm.editStore.exporting},on:{"click":function($event){_vm.editStore.runAutoCrop++}}},[_c('v-icon',[_vm._v("mdi-auto-fix")])],1)],1),_c('SubtitlesConfigBottomSheet')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }