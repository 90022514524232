<template>
  <v-dialog v-model="feedbackStore.feedback" max-width="400px" scrollable>
    <FeedbackCard />
  </v-dialog>
</template>

<script>
import { useFeedbackStore } from "@/stores/feedback";
import FeedbackCard from "@/components/feedback/FeedbackCard.vue";

export default {
  name: "FeedbackDialog",
  components: {
    FeedbackCard,
  },
  setup() {
    const feedbackStore = useFeedbackStore();
    return { feedbackStore };
  },
};
</script>

<style scoped>
:deep(.v-dialog),
:deep(.v-dialog .v-card) {
  border-radius: 16px;
}
</style>