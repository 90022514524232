import { defineStore } from 'pinia'
import axios from "axios"
import localforage from 'localforage'
import { API_URL } from '../api.js'
import { flattenObject, diffFlatten, unflatenObject } from "../util/helpers";

export const useAccountStore = defineStore("account", {
    state: () => ({
        account: {},
    }),
    getters: {
        isLoggedIn: state => !!state.account._id,
        isVerified: state => !!state.account.isVerified,
        accountTier: state => state.account.subscription && state.account.subscription.tier ? state.account.subscription.tier : null,
    },
    actions: {
        async persist() {
            await localforage.setItem("accountStore", this.$state)
        },
        async googleOAuth() {
            try {
                window.location.replace(`${API_URL}/auth/google`);
            }
            catch (error) {
                console.log(error)
            }
        },
        async fetchLoggedInAccount() {
            try {
                const resp = await axios.get(`${API_URL}/auth`, { withCredentials: true })

                this.account = resp.data.account
                await this.persist()

                return resp
            }
            catch (error) {
                if (error.response.status == 401) {
                    this.account = {}
                    await this.persist()
                }
                console.log(error)
            }
        },
        // async register(account) {
        //     try {
        //         const resp = await axios.post(`${API_URL}/accounts`, account, { withCredentials: true })
        //         const csrfToken = resp.headers["x-csrf-token"];
        //         localStorage.setItem("csrfToken", csrfToken);
        //         axios.defaults.headers.common["x-csrf-token"] = csrfToken;
        //         const data = resp.data;

        //         this.account = data.account
        //         await this.persist()

        //         return resp
        //     }
        //     catch (error) {
        //         console.log(error)
        //     }
        // },
        // async login(email, password) {
        //     try {
        //         const resp = await axios.post(`${API_URL}/sessions`, { email, password }, { withCredentials: true })
        //         const csrfToken = resp.headers["x-csrf-token"];
        //         localStorage.setItem("csrfToken", csrfToken);
        //         axios.defaults.headers.common["x-csrf-token"] = csrfToken;
        //         const data = resp.data;

        //         this.account = data.account
        //         await this.persist()

        //         return resp
        //     }
        //     catch (error) {
        //         console.log(error)
        //     }
        // },
        async logout() {
            try {
                const resp = await axios.delete(`${API_URL}/auth/logout`, { withCredentials: true })
                return resp
            }
            catch (error) {
                console.log(error)
            }
            finally {
                localStorage.removeItem("csrfToken");
                delete axios.defaults.headers.common["x-csrf-token"];
                this.account = {}
                await this.persist()
            }
        },
        async fetchAccount() {
            try {
                const resp = await axios.get(`${API_URL}/accounts/${this.account._id}`, { withCredentials: true })
                const data = resp.data;

                this.account = data.account
                await this.persist()

                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchAccountAvatar() {
            try {
                const resp = await axios.get(`${API_URL}/accounts/${this.account._id}/avatar`, { responseType: "blob", withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async fetchAccountMonthlyUsage() {
            try {
                const resp = await axios.get(`${API_URL}/accounts/${this.account._id}/usage`, { withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async upgradeSubscription(tier, billingPeriod) {
            try {
                const resp = await axios.post(`${API_URL}/accounts/${this.account._id}/upgrade`, { tier, billingPeriod }, { withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async manageSubscription() {
            try {
                const resp = await axios.post(`${API_URL}/accounts/${this.account._id}/customer-portal`, { withCredentials: true })
                return resp
            } catch (error) {
                console.log(error)
            }
        },
        async update(account) {
            try {
                const flatOldAccount = flattenObject(this.account)
                const flatNewAccount = flattenObject(account)
                const flatDiff = diffFlatten(flatOldAccount, flatNewAccount)
                const resp = await axios.patch(`${API_URL}/accounts/${this.account._id}`, flatDiff, { withCredentials: true })

                const flatUpdatedAccount = flatOldAccount
                for (const key in flatDiff) {
                    flatUpdatedAccount[key] = flatDiff[key]
                }
                const updatedAccount = unflatenObject(flatUpdatedAccount)
                this.account = updatedAccount
                await this.persist()

                return resp
            }
            catch (error) {
                console.log(error)
            }
        },
        // async forgotPassword(email) {
        //     try {
        //         const resp = await axios.post(`${API_URL}/accounts/forgot-password`, { email }, { withCredentials: true })
        //         return resp
        //     }
        //     catch (error) {
        //         console.log(error)
        //     }
        // },
        // async changePassword(token, password) {
        //     try {
        //         const resp = await axios.post(`${API_URL}/accounts/change-password`, { token, password }, { withCredentials: true })
        //         return resp
        //     }
        //     catch (error) {
        //         console.log(error)
        //     }
        // },
    }
})