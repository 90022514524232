<template>
  <v-app-bar
    flat
    color="transparent"
    app
    :class="
      $route.name === 'search' || $route.name === 'login' || ($route.name === 'edit' && $vuetify.breakpoint.xs)
        ? ''
        : $route.name === 'edit'
        ? 'gradient background-one-fourth'
        : 'gradient'
    "
  >
    <template v-if="$route.name === 'edit' && $vuetify.breakpoint.xs">
      <div class="d-flex flex-grow-1 align-center">
        <v-btn
          icon
          small
          class="mr-2"
          :to="`/shorts?videoId=${shortStore.youtubeVideoId}&language=${
            shortStore.shortLanguage || shortStore.language
          }`"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-skeleton-loader
              :loading="editStore.fetchingShort"
              class="flex-grow-1 mr-2"
              type="heading"
              style="width: 0"
            >
              <h4 v-bind="attrs" v-on="on" class="text-truncate">
                {{ shortStore.short.title }}
              </h4>
            </v-skeleton-loader>
          </template>
          <span>{{ shortStore.short.title }}</span>
        </v-tooltip>
        <!-- <v-btn
          v-if="$route.name === 'edit'"
          small
          depressed
          rounded
          color="primary"
          class="mr-2"
        >
          <v-icon small>mdi-content-save</v-icon>
        </v-btn> -->
        <v-tooltip v-if="$route.name === 'edit'" :disabled="editStore.canExport" bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on">
              <v-btn
                v-bind="attrs"
                small
                depressed
                rounded
                color="primary"
                @click="editStore.runExport++"
                :disabled="editStore.exporting || editStore.fetchingTranscript || !editStore.initialized || !editStore.canExport"
              >
                {{
                  editStore.exporting && !editStore.waitingForExport && editStore.downloading
                    ? $t("downloadingProgress", { progress: editStore.progress })
                    : editStore.exporting && !editStore.waitingForExport
                    ? $t("exportingProgress", { progress: editStore.progress })
                    : editStore.exporting && editStore.waitingForExport
                    ? $t("exportingQueued", { minutes: editStore.estimatedTimeLeft } )
                    : $t("export")
                }}
                <v-icon v-if="!editStore.exporting" right>mdi-arrow-right-circle</v-icon>
              </v-btn>
            </span>
          </template>
          <span>{{
            accountStore.isLoggedIn
              ? $t("exportUsageQuotaReachedUpgradeToHigherPlan")
              : $t("exportUsageQuotaReachedLogIn")
          }}</span>
        </v-tooltip>

        <div v-else-if="editStore.exporting">
          <v-progress-circular
            :indeterminate="editStore.progress === 0"
            :value="editStore.progress"
            size="18"
            :rotate="editStore.progress === 0 ? null : -90"
            color="primary"
            class="mr-2"
          ></v-progress-circular>
          <small>{{ editStore.waitingForExport ? $t("exportingQueued", { minutes: editStore.estimatedTimeLeft } ) : editStore.downloading ? $t("downloading") : $t("exporting") }}</small>
        </div>
      </div>
    </template>
    <template v-else>
      <router-link to="/">
        <img v-if="$vuetify.theme.dark" src="@/assets/logo-white.svg" height="14" class="mr-4" />
        <img v-else src="@/assets/logo-black.svg" height="14" class="mr-4" />
      </router-link>

      <v-breadcrumbs
        v-if="$route.name === 'edit' && $vuetify.breakpoint.smAndUp"
        :items="[
          {
            text: $t('shorts'),
            disabled: false,
            to: `/shorts?videoId=${shortStore.youtubeVideoId}&language=${
              shortStore.shortLanguage || shortStore.language
            }`,
          },
          {
            text: shortStore.short.title,
            disabled: true,
          },
        ]"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
            <v-progress-circular
              v-if="item.disabled && editStore.fetchingShort"
              indeterminate
              size="14"
            ></v-progress-circular>
            <span v-else>{{ item.text }}</span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <v-spacer></v-spacer>

      <div v-if="$route.name === 'edit'">
        <v-menu offset-y transition="slide-y-transition" rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="!editStore.initialized" small depressed rounded v-bind="attrs" v-on="on" class="mr-2">
              {{
                editStore.aspectRatio === "9:16"
                  ? $t("portrait")
                  : editStore.aspectRatio === "1:1"
                  ? $t("square")
                  : $t("landscape")
              }}
              <v-icon v-if="editStore.aspectRatio === '9:16'" right>mdi-crop-portrait</v-icon>
              <v-icon v-if="editStore.aspectRatio === '1:1'" right>mdi-crop-square</v-icon>
              <v-icon v-if="editStore.aspectRatio === '16:9'" right>mdi-crop-landscape</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group mandatory v-model="editStore.aspectRatio" color="primary">
              <v-list-item
                v-for="item in [
                  { value: '9:16', text: $t('portrait') },
                  { value: '1:1', text: $t('square') },
                  { value: '16:9', text: $t('landscape') },
                ]"
                :key="item.value"
                :value="item.value"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <!-- <v-btn
          v-if="$route.name === 'edit'"
          small
          depressed
          rounded
          color="primary"
          class="mr-2"
        >
          {{ $t("save") }}
          <v-icon right>mdi-content-save</v-icon>
        </v-btn> -->

        <v-tooltip :disabled="editStore.canExport" bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on">
              <v-btn
                v-bind="attrs"
                small
                depressed
                rounded
                color="primary"
                @click="editStore.runExport++"
                :disabled="editStore.exporting || editStore.fetchingTranscript || !editStore.initialized || !editStore.canExport"
                class="mr-2"
              >
                {{
                  editStore.exporting && !editStore.waitingForExport && editStore.downloading
                    ? $t("downloadingProgress", { progress: editStore.progress })
                    : editStore.exporting && !editStore.waitingForExport
                    ? $t("exportingProgress", { progress: editStore.progress })
                    : editStore.exporting && editStore.waitingForExport
                    ? $t("exportingQueued", { minutes: editStore.estimatedTimeLeft } )
                    : $t("export")
                }}
                <v-icon v-if="!editStore.exporting" right>mdi-arrow-right-circle</v-icon>
              </v-btn>
            </span>
          </template>
          <span>{{
            accountStore.isLoggedIn
              ? $t("exportUsageQuotaReachedUpgradeToHigherPlan")
              : $t("exportUsageQuotaReachedLogIn")
          }}</span>
        </v-tooltip>
      </div>

      <div v-else-if="editStore.exporting" class="mr-4">
        <v-progress-circular
          :indeterminate="editStore.progress === 0"
          :value="editStore.progress"
          size="18"
          :rotate="editStore.progress === 0 ? null : -90"
          color="primary"
          class="mr-2"
        ></v-progress-circular>
        <small>{{ editStore.waitingForExport ? $t("exportingQueued", { minutes: editStore.estimatedTimeLeft } ) : editStore.downloading ? $t("downloading") : $t("exporting") }}</small>
      </div>

      <router-link v-if="accountStore.isLoggedIn" to="/account">
        <v-list-item-avatar color="primary" size="32" class="ma-0 justify-center">
          <v-img
            v-if="accountStore.isLoggedIn && accountStore.account.avatar"
            :src="accountStore.account.avatar"
          ></v-img>
          <span v-else class="white--text caption justify-center">{{
            accountStore.account.info.name
              .split(" ")
              .map((n) => n[0])
              .join("")
          }}</span>
        </v-list-item-avatar>
      </router-link>
      <v-btn v-else icon :color="$vuetify.theme.dark ? 'white' : 'primary'" to="/account">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import { useAccountStore } from "@/stores/account";
import { useEditStore } from "@/stores/edit";
import { useShortStore } from "@/stores/short";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "TopNavigation",
  setup() {
    const accountStore = useAccountStore();
    const editStore = useEditStore();
    const shortStore = useShortStore();
    const snackbarStore = useSnackbarStore();
    return { accountStore, editStore, shortStore, snackbarStore };
  },
};
</script>

<style scoped>
.theme--dark .gradient {
  background: linear-gradient(0deg, hsla(0, 0%, 7%, 0), hsla(0, 0%, 7%, 0.8) 25%, #121212 75%);
}

.theme--light .gradient {
  background: linear-gradient(0deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.85) 25%, #fff 75%);
}

.gradient.background-one-fourth {
  background-size: 25% 100%;
  background-repeat: no-repeat;
}

:deep(.v-breadcrumbs.theme--dark a.v-breadcrumbs__item:not(.v-breadcrumbs__item--disabled)) {
  color: white;
}
</style>
