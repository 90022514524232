<template>
  <SubtitlesEditBottomSheet v-if="$vuetify.breakpoint.smAndDown" />
  <SubtitlesEditDialog v-else />
</template>

<script>
import SubtitlesEditBottomSheet from "@/components/edit/SubtitlesEditBottomSheet.vue";
import SubtitlesEditDialog from "@/components/edit/SubtitlesEditDialog.vue";

export default {
  name: "SubtitlesEdit",
  components: {
    SubtitlesEditBottomSheet,
    SubtitlesEditDialog,
  },
};
</script>