<template>
  <div class="subtitle-config-wrapper">
    <div class="d-flex justify-space-between align-center">
      <div>
        <h4 :class="editStore.enableSubtitles ? 'text--primary' : 'text--disabled'">
          {{ $t("subtitleStyle") }}
        </h4>
        <span :class="editStore.enableSubtitles ? 'subtitle-2 text--secondary' : 'subtitle-2 text--disabled'">{{
          $t("subtitleStyleCaption")
        }}</span>
      </div>
      <v-btn
        v-if="accountStore.isLoggedIn && $vuetify.breakpoint.xs"
        icon
        @click="
          subtitlesPresetsMounted = true;
          subtitlesPresetStore.managePresets = true;
        "
        :loading="subtitlesPresetStore.syncing"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-item-group mandatory v-model="subtitlesStyleId" @change="applyStyle()" class="mb-4">
      <v-container class="px-0 pb-0">
        <v-row dense>
          <v-col
            v-for="style in subtitlesStyles.concat(subtitlesPresetStore.subtitlesPresets)"
            :key="style._id ? style._id : style.value"
            cols="4"
            lg="3"
          >
            <v-item v-slot="{ active, toggle }" :value="style._id ? style._id : style.value">
              <v-sheet
                v-if="style._id"
                @click="editStore.enableSubtitles ? toggle() : null"
                flat
                dark
                rounded="lg"
                color="#1F1F1F"
                :class="active ? 'active d-flex justify-center align-center' : 'd-flex justify-center align-center'"
                min-height="50"
                height="100%"
              >
                <v-progress-circular
                  v-if="subtitlesPresetStore.fetchingPresetId === style._id"
                  indeterminate
                  size="24"
                  width="2"
                ></v-progress-circular>
                <span v-else class="caption text-center">{{ style.name }}</span>
              </v-sheet>
              <v-img
                v-else
                :class="active ? 'active rounded-lg' : 'rounded-lg'"
                @click="editStore.enableSubtitles ? toggle() : null"
                :src="style.imageUrl"
                lazy-src="/img/subtitle-styles/placeholder.png"
                height="100%"
              ></v-img>
            </v-item>
          </v-col>
          <v-col v-if="accountStore.isLoggedIn && !$vuetify.breakpoint.xs" cols="4" lg="3">
            <v-card
              flat
              dark
              rounded="lg"
              color="#1F1F1F"
              class="d-flex justify-center align-center"
              min-height="50"
              height="100%"
            >
              <v-btn
                icon
                @click="
                  subtitlesPresetsMounted = true;
                  subtitlesPresetStore.managePresets = true;
                "
                :loading="subtitlesPresetStore.syncing"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>

    <h4 :class="editStore.enableSubtitles ? 'text--primary' : 'text--disabled'">
      {{ $t("subtitleTransition") }}
    </h4>
    <v-chip-group
      mandatory
      v-model="editStore.subtitlesConfig.transition"
      active-class="primary--text"
      column
      class="mb-4"
    >
      <v-chip
        v-for="transition in transitions"
        :key="transition.value"
        :value="transition.value"
        filter
        :disabled="!editStore.enableSubtitles"
      >
        {{ transition.text }}
      </v-chip>
    </v-chip-group>

    <h4 :class="editStore.enableSubtitles ? 'text--primary' : 'text--disabled'">
      {{ $t("wordHighlight") }}
    </h4>
    <div class="mb-4">
      <v-chip-group
        mandatory
        v-model="editStore.subtitlesConfig.wordHighlight"
        active-class="primary--text"
        column
        @change="
          editStore.subtitlesConfig.wordHighlight === 'background'
            ? (editStore.subtitlesConfig.enableBackgroundBox = false)
            : null
        "
      >
        <v-chip
          v-for="wordHighlight in wordHighlights"
          :key="wordHighlight.value"
          :value="wordHighlight.value"
          filter
          :disabled="!editStore.enableSubtitles"
        >
          {{ wordHighlight.text }}
        </v-chip>
      </v-chip-group>

      <v-menu
        v-if="editStore.subtitlesConfig.wordHighlight !== 'none'"
        offset-y
        transition="slide-y-transition"
        rounded="lg"
        :close-on-content-click="false"
        :max-width="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="editStore.subtitlesConfig.colors.primaryColor"
            :disabled="!editStore.enableSubtitles"
            readonly
            :label="$t('primaryColor')"
            rounded
            filled
            dense
            single-line
            hide-details
            v-bind="attrs"
            v-on="on"
            class="mb-2"
          >
            <template v-slot:prepend-inner>
              <v-icon :color="editStore.subtitlesConfig.colors.primaryColor" class="mr-2">mdi-circle</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-color-picker v-model="editStore.subtitlesConfig.colors.primaryColor" mode="hexa"></v-color-picker>
      </v-menu>
    </div>

    <h4 :class="editStore.enableSubtitles ? 'text--primary' : 'text--disabled'">
      {{ $t("subtitleSplitting") }}
    </h4>
    <span :class="editStore.enableSubtitles ? 'subtitle-2 text--secondary' : 'subtitle-2 text--disabled'">{{
      $t("subtitleSplittingCaption")
    }}</span>
    <v-chip-group
      mandatory
      v-model="editStore.subtitlesConfig.maxCharsPerBlock"
      active-class="primary--text"
      column
      class="mb-4"
    >
      <v-chip
        v-for="splitting in splittings"
        :key="splitting.value"
        :value="splitting.value"
        filter
        :disabled="!editStore.enableSubtitles"
      >
        {{ splitting.text }}
      </v-chip>
    </v-chip-group>

    <h4 :class="editStore.enableSubtitles ? 'text--primary' : 'text--disabled'">
      {{ $t("subtitlePosition") }}
    </h4>
    <v-chip-group
      mandatory
      v-model="editStore.subtitlesConfig.blockVerticalPosition"
      active-class="primary--text"
      column
      class="mb-4"
    >
      <v-chip
        v-for="position in positions"
        :key="position.value"
        :value="position.value"
        filter
        :disabled="!editStore.enableSubtitles"
      >
        {{ position.text }}
      </v-chip>
    </v-chip-group>

    <h4 :class="editStore.enableSubtitles ? 'text--primary' : 'text--disabled'">
      {{ $t("fontSettings") }}
    </h4>
    <div class="d-flex my-2">
      <v-select
        class="flex-grow-1"
        style="border-radius: 28px 0 0 28px; width: 0"
        v-model="editStore.subtitlesConfig.font.fontName"
        :items="['Roboto', 'Montserrat', 'Poppins', 'Playpen Sans']"
        :menu-props="{
          transition: 'slide-y-transition',
          'offset-y': true,
          rounded: 'lg',
        }"
        :disabled="!editStore.enableSubtitles"
        :label="$t('fontName')"
        rounded
        dense
        filled
        hide-details
        append-icon
      >
      </v-select>
      <v-select
        class="flex-grow-1"
        style="border-radius: 0; width: 0"
        v-model="editStore.subtitlesConfig.font.fontWeight"
        :menu-props="{
          transition: 'slide-y-transition',
          'offset-y': true,
          rounded: 'lg',
        }"
        :disabled="!editStore.enableSubtitles"
        :label="$t('fontWeight')"
        :items="[
          { value: 400, text: $t('regular') },
          { value: 700, text: $t('bold') },
        ]"
        rounded
        dense
        filled
        hide-details
        append-icon
      >
      </v-select>
      <v-text-field
        class="flex-grow-1"
        style="border-radius: 0 28px 28px 0; width: 0"
        v-model.number="editStore.subtitlesConfig.font.fontSize"
        :disabled="!editStore.enableSubtitles"
        :label="$t('fontSize')"
        type="number"
        :min="25"
        :max="150"
        step="1"
        rounded
        dense
        filled
        hide-details
      ></v-text-field>
    </div>
    <v-menu offset-y transition="slide-y-transition" rounded="lg" :close-on-content-click="false" :max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="editStore.subtitlesConfig.colors.secondaryColor"
          :disabled="!editStore.enableSubtitles"
          readonly
          :label="$t('fontColor')"
          rounded
          filled
          dense
          hide-details
          v-bind="attrs"
          v-on="on"
          class="mb-2"
        >
          <template v-slot:prepend-inner>
            <v-icon :color="editStore.subtitlesConfig.colors.secondaryColor" class="mr-2">mdi-circle</v-icon>
          </template>
        </v-text-field>
      </template>
      <v-color-picker v-model="editStore.subtitlesConfig.colors.secondaryColor" mode="hexa"></v-color-picker>
    </v-menu>

    <div class="d-flex mb-4">
      <v-menu offset-y transition="slide-y-transition" rounded="lg" :close-on-content-click="false" :max-width="300">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="flex-grow-1"
            style="border-radius: 28px 0 0 28px; width: 0"
            v-model="editStore.subtitlesConfig.colors.outlineColor"
            :disabled="!editStore.enableSubtitles"
            readonly
            :label="$t('outlineColor')"
            rounded
            filled
            dense
            hide-details
            v-bind="attrs"
            v-on="on"
          >
            <template v-slot:prepend-inner>
              <v-icon :color="editStore.subtitlesConfig.colors.outlineColor" class="mr-2">mdi-circle</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-color-picker v-model="editStore.subtitlesConfig.colors.outlineColor" mode="hexa"></v-color-picker>
      </v-menu>
      <v-text-field
        class="flex-grow-1"
        style="border-radius: 0 28px 28px 0; width: 0"
        v-model.number="editStore.subtitlesConfig.font.fontStrokeSize"
        :disabled="!editStore.enableSubtitles || editStore.subtitlesConfig.enableBackgroundBox"
        :label="$t('strokeSize')"
        type="number"
        :min="0"
        :max="50"
        step="1"
        rounded
        dense
        filled
        hide-details
      ></v-text-field>
    </div>

    <h4 :class="editStore.enableSubtitles ? 'text--primary' : 'text--disabled'">
      {{ $t("otherSettings") }}
    </h4>
    <div class="d-flex align-center justify-space-between my-2">
      <div class="d-flex align-center">
        <v-icon class="mr-2" :disabled="!editStore.enableSubtitles">mdi-format-letter-case-upper</v-icon>
        <span :class="editStore.enableSubtitles ? 'subtitle-1' : 'subtitle-1 text--disabled'">{{
          $t("enableUppercase")
        }}</span>
      </div>
      <v-switch
        v-model="editStore.subtitlesConfig.enableUppercase"
        :disabled="!editStore.enableSubtitles"
        inset
        dense
        color="primary"
        hide-details
        class="ma-0"
      ></v-switch>
    </div>
    <div class="d-flex align-center justify-space-between my-2">
      <div class="d-flex align-center">
        <v-icon class="mr-2" :disabled="!editStore.enableSubtitles">mdi-text-box</v-icon>
        <span :class="editStore.enableSubtitles ? 'subtitle-1' : 'subtitle-1 text--disabled'">{{
          $t("enableBackgroundBox")
        }}</span>
      </div>
      <v-switch
        v-model="editStore.subtitlesConfig.enableBackgroundBox"
        :disabled="!editStore.enableSubtitles || editStore.subtitlesConfig.wordHighlight === 'background'"
        inset
        dense
        color="primary"
        hide-details
        class="ma-0"
      ></v-switch>
    </div>
    <div class="d-flex align-center justify-space-between my-2">
      <div class="d-flex align-center">
        <v-icon class="mr-2" :disabled="!editStore.enableSubtitles">mdi-eye-off</v-icon>
        <span :class="editStore.enableSubtitles ? 'subtitle-1' : 'subtitle-1 text--disabled'">{{
          $t("enableWordReveal")
        }}</span>
      </div>
      <v-switch
        v-model="editStore.subtitlesConfig.enableWordReveal"
        :disabled="!editStore.enableSubtitles"
        inset
        dense
        color="primary"
        hide-details
        class="ma-0"
      ></v-switch>
    </div>

    <SubtitlesEdit v-if="mountSubtitlesEdit" />
    <SubtitlesPresets v-if="subtitlesPresetsMounted" />
  </div>
</template>

<script>
import SubtitlesEdit from "@/components/edit/SubtitlesEdit.vue";
import SubtitlesPresets from "@/components/edit/SubtitlesPresets.vue";
import { useEditStore } from "@/stores/edit";
import { useSubtitlesPresetStore } from "@/stores/subtitles-preset";
import { useAccountStore } from "@/stores/account";

export default {
  name: "SubtitlesConfig",
  props: ["mountSubtitlesEdit"],
  components: { SubtitlesEdit, SubtitlesPresets },
  setup() {
    const editStore = useEditStore();
    const subtitlesPresetStore = useSubtitlesPresetStore();
    const accountStore = useAccountStore();
    return { editStore, subtitlesPresetStore, accountStore };
  },
  data() {
    return {
      subtitlesPresetsMounted: false,
      subtitlesStyleId: "karaoke-background",
      // TODO: Move these constants to subtitlesPresetStore (to share with SubtitlesPresetsCard)
      subtitlesStyles: [
        {
          value: "simple",
          text: this.$t("simple"),
          imageUrl: "/img/subtitle-styles/simple.gif",
        },
        {
          value: "simple-box",
          text: this.$t("simpleBox"),
          imageUrl: "/img/subtitle-styles/simple-box.gif",
        },
        {
          value: "fast",
          text: this.$t("fast"),
          imageUrl: "/img/subtitle-styles/fast.gif",
        },
        {
          value: "karaoke",
          text: this.$t("karaoke"),
          imageUrl: "/img/subtitle-styles/karaoke.gif",
        },
        {
          value: "karaoke-background",
          text: this.$t("karaokeBackground"),
          imageUrl: "/img/subtitle-styles/karaoke-background.gif",
        },
        {
          value: "reveal",
          text: this.$t("reveal"),
          imageUrl: "/img/subtitle-styles/reveal.gif",
        },
      ],
      transitions: [
        { value: "none", text: this.$t("none") },
        { value: "subtitle-bounce", text: this.$t("blockBounce") },
        { value: "subtitle-slideup", text: this.$t("blockSlideup") },
        { value: "word-bounce", text: this.$t("wordBounce") },
        { value: "word-slideup", text: this.$t("wordSlideup") },
      ],
      wordHighlights: [
        { value: "none", text: this.$t("none") },
        { value: "current-only", text: this.$t("currentOnly") },
        { value: "upto-current", text: this.$t("upToCurrent") },
        { value: "background", text: this.$t("background") },
      ],
      splittings: [
        { value: 1, text: this.$t("oneWord") },
        { value: 10, text: this.$t("nLetters", { letters: 10 }) },
        { value: 15, text: this.$t("nLetters", { letters: 15 }) },
        { value: 20, text: this.$t("nLetters", { letters: 20 }) },
        { value: 25, text: this.$t("nLetters", { letters: 25 }) },
        { value: 30, text: this.$t("nLetters", { letters: 30 }) },
        { value: 999, text: this.$t("none") },
      ],
      positions: [
        // { value: "auto", text: this.$t("auto") },
        { value: "top", text: this.$t("top") },
        { value: "middle", text: this.$t("middle") },
        { value: "bottom", text: this.$t("bottom") },
      ],
    };
  },
  mounted() {
    this.applyStyle();
  },
  methods: {
    async applyStyle() {
      switch (this.subtitlesStyleId) {
        case "simple":
          this.editStore.subtitlesConfig.transition = "subtitle-bounce";
          this.editStore.subtitlesConfig.wordHighlight = "none";
          this.editStore.subtitlesConfig.enableBackgroundBox = false;
          this.editStore.subtitlesConfig.colors.secondaryColor = "#FFFFFFFF";
          this.editStore.subtitlesConfig.colors.outlineColor = "#000000FF";
          this.editStore.subtitlesConfig.font.fontStrokeSize = 20;
          this.editStore.subtitlesConfig.maxCharsPerBlock = 20;
          this.editStore.subtitlesConfig.enableWordReveal = false;
          break;
        case "simple-box":
          this.editStore.subtitlesConfig.transition = "none";
          this.editStore.subtitlesConfig.wordHighlight = "none";
          this.editStore.subtitlesConfig.enableBackgroundBox = true;
          this.editStore.subtitlesConfig.colors.secondaryColor = "#FFFFFFFF";
          this.editStore.subtitlesConfig.colors.outlineColor = "#0169EEFF";
          this.editStore.subtitlesConfig.maxCharsPerBlock = 20;
          this.editStore.subtitlesConfig.enableWordReveal = false;
          break;
        case "fast":
          this.editStore.subtitlesConfig.transition = "none";
          this.editStore.subtitlesConfig.wordHighlight = "none";
          this.editStore.subtitlesConfig.enableBackgroundBox = false;
          this.editStore.subtitlesConfig.colors.secondaryColor = "#FFFFFFFF";
          this.editStore.subtitlesConfig.colors.outlineColor = "#000000FF";
          this.editStore.subtitlesConfig.font.fontStrokeSize = 20;
          this.editStore.subtitlesConfig.maxCharsPerBlock = 1;
          break;
        case "karaoke":
          this.editStore.subtitlesConfig.transition = "word-bounce";
          this.editStore.subtitlesConfig.wordHighlight = "current-only";
          this.editStore.subtitlesConfig.enableBackgroundBox = false;
          this.editStore.subtitlesConfig.colors = {
            primaryColor: "#04F827FF",
            secondaryColor: "#FFFFFFFF",
            outlineColor: "#000000FF",
          };
          this.editStore.subtitlesConfig.font.fontStrokeSize = 20;
          this.editStore.subtitlesConfig.maxCharsPerBlock = 20;
          break;
        case "karaoke-background":
          this.editStore.subtitlesConfig.transition = "word-bounce";
          this.editStore.subtitlesConfig.wordHighlight = "background";
          this.editStore.subtitlesConfig.enableBackgroundBox = false;
          this.editStore.subtitlesConfig.colors = {
            primaryColor: "#0169EEFF",
            secondaryColor: "#FFFFFFFF",
            outlineColor: "#000000FF",
          };
          this.editStore.subtitlesConfig.font.fontStrokeSize = 0;
          this.editStore.subtitlesConfig.maxCharsPerBlock = 20;
          break;
        case "reveal":
          this.editStore.subtitlesConfig.transition = "word-slideup";
          this.editStore.subtitlesConfig.wordHighlight = "current-only";
          this.editStore.subtitlesConfig.enableBackgroundBox = false;
          this.editStore.subtitlesConfig.colors = {
            primaryColor: "#04F827FF",
            secondaryColor: "#FFFFFFFF",
            outlineColor: "#000000FF",
          };
          this.editStore.subtitlesConfig.font.fontStrokeSize = 20;
          this.editStore.subtitlesConfig.maxCharsPerBlock = 20;
          this.editStore.subtitlesConfig.enableWordReveal = true;
          break;
        default:
          try {
            let subtitlesPreset = this.subtitlesPresetStore.subtitlesPresets.find(
              (e) => e._id === this.subtitlesStyleId
            );
            if (!subtitlesPreset || Object.keys(subtitlesPreset).length <= 2)
              await this.subtitlesPresetStore.fetchSubtitlesPreset(this.subtitlesStyleId);

            subtitlesPreset = this.subtitlesPresetStore.subtitlesPresets.find((e) => e._id === this.subtitlesStyleId);

            this.editStore.subtitlesConfig.transition = subtitlesPreset.transition;
            this.editStore.subtitlesConfig.wordHighlight = subtitlesPreset.wordHighlight;
            this.editStore.subtitlesConfig.maxCharsPerBlock = subtitlesPreset.maxCharsPerBlock;
            this.editStore.subtitlesConfig.enableUppercase = subtitlesPreset.enableUppercase;
            this.editStore.subtitlesConfig.enableBackgroundBox = subtitlesPreset.enableBackgroundBox;
            this.editStore.subtitlesConfig.enableWordReveal = subtitlesPreset.enableWordReveal;
            this.editStore.subtitlesConfig.font = {
              fontName: subtitlesPreset.font.fontName,
              fontWeight: subtitlesPreset.font.fontWeight,
              fontSize: subtitlesPreset.font.fontSize,
              fontStrokeSize: subtitlesPreset.font.fontStrokeSize,
            };
            this.editStore.subtitlesConfig.colors = {
              primaryColor: subtitlesPreset.colors.primaryColor,
              secondaryColor: subtitlesPreset.colors.secondaryColor,
              outlineColor: subtitlesPreset.colors.outlineColor,
            };
          } catch (err) {
            console.log(err);
          }
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 600px) {
  .subtitle-config-wrapper {
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

:deep(.v-input--selection-controls__input) {
  margin-right: 4px !important;
}

.active,
.theme--dark.v-sheet.active {
  border: 4px solid #0169ee;
  border-color: #0169ee !important;
}
</style>
