<template>
  <v-card>
    <v-card-title
      class="font-weight-bold d-flex align-center justify-space-between"
    >
      <span>{{ $t("feedback") }}</span>
      <v-btn icon @click="feedbackStore.feedback = false">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>{{ $t("shareYourFeedbackWithUs") }}</v-card-subtitle>
    <v-card-text>
      <div v-if="step === 1">
        <div class="d-flex align-start justify-space-between text-center">
          <div class="btn-wrapper">
            <v-btn
              depressed
              fab
              elevation="0"
              :color="feedbackStore.tag === 'issue' ? 'primary' : null"
              class="mb-2"
              @click="feedbackStore.tag = 'issue'"
            >
              <div>
                <v-icon>mdi-alert</v-icon>
              </div>
            </v-btn>
            <div class="caption">{{ $t("reportIssue") }}</div>
          </div>
          <div class="btn-wrapper">
            <v-btn
              depressed
              fab
              elevation="0"
              :color="feedbackStore.tag === 'idea' ? 'primary' : null"
              class="mb-2"
              @click="feedbackStore.tag = 'idea'"
            >
              <div>
                <v-icon>mdi-lightbulb</v-icon>
              </div>
            </v-btn>
            <div class="caption">{{ $t("shareIdea") }}</div>
          </div>
          <div class="btn-wrapper">
            <v-btn
              depressed
              fab
              elevation="0"
              :color="feedbackStore.tag === 'other' ? 'primary' : null"
              class="mb-2"
              @click="feedbackStore.tag = 'other'"
            >
              <div>
                <v-icon>mdi-message-text</v-icon>
              </div>
            </v-btn>
            <div class="caption">{{ $t("other") }}</div>
          </div>
        </div>
      </div>
      <div v-if="step === 2">
        <v-textarea
          v-model="feedbackStore.message"
          :placeholder="$t('startWritingHere')"
          class="pa-0"
          rounded
          filled
          auto-grow
          hide-details
        >
        </v-textarea>
      </div>
    </v-card-text>
    <v-card-actions class="pt-0 px-6 pb-4">
      <v-btn
        color="primary"
        depressed
        rounded
        block
        @click="step === 1 ? step++ : submitFeedback()"
        :disabled="step === 1 ? !feedbackStore.tag : !feedbackStore.message"
        :loading="loading"
        >{{ step === 2 ? $t("submit") : $t("next") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { useFeedbackStore } from "@/stores/feedback";
import { useSnackbarStore } from "@/stores/snackbar";

export default {
  name: "FeedbackCard",
  setup() {
    const feedbackStore = useFeedbackStore();
    const snackbarStore = useSnackbarStore();
    return { feedbackStore, snackbarStore };
  },
  data() {
    return { step: 1, loading: false };
  },
  methods: {
    async submitFeedback() {
      try {
        this.loading = true;
        await this.feedbackStore.sendFeedback();
        this.step = 1;
        this.snackbarStore.text = this.$t("thankYouForYourFeedback");
      } catch (error) {
        this.snackbarStore.text = this.$t("couldNotSendYourFeedback");
      }
      this.snackbarStore.snackbar = true;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.btn-wrapper {
  width: 33%;
}
</style>